import * as Yup from 'yup';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { GoogleLogin } from '@react-oauth/google';
import { setUserId, setOptOut } from '@amplitude/analytics-browser';
// material
import {
  Link,
  Box,
  Stack,
  Alert,
  Divider,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
//
import { MIconButton } from '../../@material-extend';
import {
  ButtonTracking,
  stockBtnTabEventTracking,
  event_types,
  signInTracking
} from 'components/amplitute';

// ----------------------------------------------------------------------
type InitialValues = {
  email: string;
  password: string;
  remember: boolean;
  afterSubmit?: string;
};
export default function LoginForm() {
  const { login, googleLogin } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required')
  });

  const formik = useFormik<InitialValues>({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        await login(values.email, values.password);
        signInTracking(window.location.href, values.email);
        setUserId(values.email);
        setOptOut(false);
        enqueueSnackbar('Login success', {
          variant: 'success',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
        // @ts-ignore
        if (location?.state?.redirectURL) {
          // @ts-ignore
          navigate(location?.state?.redirectURL);
        }
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error) {
        console.error(error);
        resetForm();
        if (isMountedRef.current) {
          setSubmitting(false);
          // @ts-ignore
          setErrors({ afterSubmit: error.message });
        }
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    // ButtonTracking(
    //   `Show Password Button Click ${window.location.pathname}`,
    //   window.location.href,
    //   window.location.pathname
    // );
    stockBtnTabEventTracking(
      'LoginPage',
      '',
      event_types.btnClick,
      `Show Password`,
      'Authentication',
      window.location.href
    );
    setShowPassword((show) => !show);
  };

  const responseGoogle = async (response: any) => {
    if (!response?.error) {
      // ButtonTracking(
      //   `Login with Google Button Click ${window.location.pathname}`,
      //   window.location.href,
      //   window.location.pathname
      // );
      stockBtnTabEventTracking(
        'LoginPage',
        '',
        event_types.btnClick,
        `Login with Google`,
        'Authentication',
        window.location.href
      );
      await googleLogin(response);
      // @ts-ignore
      if (location?.state?.redirectURL) {
        // @ts-ignore
        console.log(location?.state?.redirectURL);
        // @ts-ignore
        navigate(location?.state?.redirectURL);
      }
    } else {
      console.log(response);
    }
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}

          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Password"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />

          <Link
            onClick={() => {
              // ButtonTracking(
              //   `Forgot password? Login Button Click ${window.location.pathname}`,
              //   window.location.href,
              //   window.location.pathname
              // );
              stockBtnTabEventTracking(
                'LoginPage',
                '',
                event_types.btnClick,
                `Forgot password? Login`,
                'Authentication',
                window.location.href
              );
            }}
            component={RouterLink}
            variant="subtitle2"
            to={PATH_AUTH.forgotPassword}
          >
            Forgot password?
          </Link>
        </Stack>

        <LoadingButton
          onClick={() => {
            // ButtonTracking(
            //   `Login Button Click ${window.location.pathname}`,
            //   window.location.href,
            //   window.location.pathname
            // );
            stockBtnTabEventTracking(
              'LoginPage',
              '',
              event_types.btnClick,
              `Login Button`,
              'Authentication',
              window.location.href
            );
          }}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Login
        </LoadingButton>

        <Divider sx={{ my: 2 }} />

        <Stack spacing={3} direction="row" sx={{ display: 'flex', justifyContent: 'center' }}>
          <GoogleLogin
            // style={{ width: '100%' }}
            // clientId="682035585205-347aklfh589s4mo63q72s218cgktqp3m.apps.googleusercontent.com"
            // buttonText="Login"
            onSuccess={responseGoogle}
            // onError={responseGoogle}
            // onSuccess={(credentialResponse) => {
            //   console.log(credentialResponse);
            // }}
            onError={() => {
              console.log('Login Failed');
            }}
            // cookiePolicy={'single_host_origin'}
          />
        </Stack>
      </Form>
    </FormikProvider>
  );
}
