import { Link as ScrollLink } from 'react-scroll';
import { useLocation, Outlet } from 'react-router-dom';
// material
import { Box, Link, Container, Typography } from '@mui/material';
// components
import Logo from '../../components/Logo';
//
import MainFooter from './MainFooter';
import MainNavbar from './MainNavbar';
import useAuth from 'hooks/useAuth';
import { useEffect } from 'react';
import { init } from '@amplitude/analytics-browser';
import { API_KEY } from 'utils/apiKeyAmplitute';
import { ButtonTracking, stockBtnTabEventTracking, event_types } from 'components/amplitute';
import ContactFloatingButton from './ContactFloatingButton';
import { RootState, useSelector } from '../../redux/store';

// ----------------------------------------------------------------------

export default function MainLayout() {
  const { pathname } = useLocation();
  const isHome = pathname === '/';
  let { user } = useAuth();
  const { theme: pageTheme } = useSelector((state: RootState) => state.theme);
  useEffect(() => {
    init(API_KEY, user?.email ? user?.email : 'N/A');
  }, []);

  return (
    <>
      <MainNavbar />
      <div className={pageTheme}>
        <Outlet />
      </div>
      {!isHome ? (
        <>
          <MainFooter />
          {pathname.includes('insidertrading') ? <ContactFloatingButton /> : null}
        </>
      ) : (
        <Box
          sx={{
            py: 5,
            textAlign: 'center',
            position: 'relative',
            bgcolor: 'background.default'
          }}
        >
          <Container maxWidth="lg">
            <ScrollLink
              to="move_top"
              onClick={() => {
                // ButtonTracking(
                //   `Footer Logo Click ${window.location.pathname}`,
                //   window.location.href,
                //   window.location.pathname
                // );
                stockBtnTabEventTracking(
                  'Footer',
                  '',
                  event_types.btnClick,
                  `Footer Logo`,
                  'Footer Logo',
                  window.location.href
                );
              }}
              spy
              smooth
            >
              <Logo sx={{ mb: 1, mx: 'auto', cursor: 'pointer' }} />
            </ScrollLink>
            <ContactFloatingButton />
            <Typography variant="caption" component="p">
              © All rights reserved
              <br /> made by &nbsp;
            </Typography>
          </Container>
        </Box>
      )}
    </>
  );
}
