const reStrcutureForm4Link = (form4Link: string) => {
  let url = form4Link?.split('/');
  if (url) {
    url.splice(url.length - 1, 0, 'xslF345X05');
    return url?.join('/');
  }

  return '';
};

export default reStrcutureForm4Link;
