import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { UserManager } from '../../@types/user';

// ----------------------------------------------------------------------

type UserState = {
  showLoginRegistrationModal: string;
  showCloseButton: boolean;
  showSubHeading: boolean;
  isLoading: boolean;
  error: boolean;
  userList: UserManager[];
  user?: UserManager;
  preferenceResponse?: any;
};

const initialState: UserState = {
  showLoginRegistrationModal: '',
  showCloseButton: true,
  showSubHeading: true,
  isLoading: false,
  error: false,
  userList: [],
  user: undefined,
  preferenceResponse: {}
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    setShowLoginModal(state) {
      state.showLoginRegistrationModal = 'login';
      state.showCloseButton = true;
      state.showSubHeading = true;
    },
    setShowRegistrationModal(state) {
      state.showLoginRegistrationModal = 'registration';
      state.showCloseButton = true;
      state.showSubHeading = true;
    },
    setShowRegistrationModalWithoutLogin(state) {
      state.showLoginRegistrationModal = 'registration';
      state.showCloseButton = true;
      state.showSubHeading = false;
    },
    setShowLoginModalWithoutClose(state) {
      state.showLoginRegistrationModal = 'login';
      state.showCloseButton = false;
      state.showSubHeading = true;
    },
    setShowRegistrationModalWithoutClose(state) {
      state.showLoginRegistrationModal = 'registration';
      state.showCloseButton = false;
      state.showSubHeading = true;
    },
    setHideLoginRegModal(state) {
      state.showLoginRegistrationModal = '';
      state.showCloseButton = true;
      state.showSubHeading = true;
    },
    startLoading(state) {
      state.isLoading = true;
    },
    endLoading(state) {
      state.isLoading = false;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // // GET PROFILE
    // getProfileSuccess(state, action) {
    //   state.isLoading = false;
    //   state.myProfile = action.payload;
    // },

    // DELETE USERS
    deleteUser(state, action) {
      const deleteUser = filter(state.userList, (user) => user._id !== action.payload);
      state.userList = deleteUser;
    },

    // GET MANAGE USERS
    getUserListSuccess(state, action) {
      state.isLoading = false;
      state.userList = action.payload;
    },

    getUserSuccess(state, action) {
      state.isLoading = false;
      state.user = action.payload;
    },
    getPreferenceSuccess(state, action) {
      state.isLoading = false;
      state.preferenceResponse = action.payload;
    },
    resetPreferenceSuccess(state) {
      state.preferenceResponse = {};
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  deleteUser,
  setShowLoginModal,
  setShowRegistrationModal,
  setShowRegistrationModalWithoutLogin,
  setHideLoginRegModal,
  setShowLoginModalWithoutClose,
  setShowRegistrationModalWithoutClose,
  resetPreferenceSuccess
} = slice.actions;

// ----------------------------------------------------------------------

// export function getProfile() {
//   return async () => {
//     dispatch(slice.actions.startLoading());
//     try {
//       const response = await axios.get('/api/user/profile');
//       dispatch(slice.actions.getProfileSuccess(response.data.profile));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

// ----------------------------------------------------------------------

export function getUserList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/v1/user/all');
      dispatch(slice.actions.getUserListSuccess(response.data.users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUser(userId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1/user/${userId}`);
      dispatch(slice.actions.getUserSuccess(response.data.user));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateUser(userId: string, user: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/api/v1/user/${userId}`, user);
      dispatch(slice.actions.getUserSuccess(response.data.user));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function savePreferences(preferences: { [key: string]: boolean }, token?: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/api/v1/user/save-email-preference`, {
        preferences,
        token
      });
      dispatch(slice.actions.getPreferenceSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function createUser(user: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/api/v1/user/`, user);
      dispatch(slice.actions.getUserSuccess(response.data.user));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function forgotPassword(email: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/v1/user/remove', { email });
      dispatch(slice.actions.endLoading());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
