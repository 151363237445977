import { Icon } from '@iconify/react';
import { useState, useEffect, ReactNode } from 'react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import { NavLink as RouterLink, useLocation, useParams } from 'react-router-dom';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import { PATH_AUTH } from '../../routes/paths';

// material
import { alpha, styled } from '@mui/material/styles';
import {
  Box,
  List,
  Link,
  Drawer,
  Button,
  Collapse,
  Typography,
  LinkProps,
  ButtonProps,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  ListItemButtonProps
} from '@mui/material';
// components
import Logo from '../../components/Logo';
import NavSection from '../../components/NavSection';
import Scrollbar from '../../components/Scrollbar';
import { MIconButton } from '../../components/@material-extend';
//
import { MenuProps, MenuItemProps } from './MainNavbar';
// hooks
import useAuth from 'hooks/useAuth';
import { ButtonTracking, stockBtnTabEventTracking, event_types } from 'components/amplitute';
import { trackEvent } from '../../components/amplitute';
import { dispatch } from '../../redux/store';
import { setShowLoginModal, setShowRegistrationModal } from '../../redux/slices/user';
// ----------------------------------------------------------------------

const ICON_SIZE = 22;
const ITEM_SIZE = 48;
const PADDING = 2.5;

type StyleProps = LinkProps & ListItemButtonProps;

interface StyledButtonProps extends ButtonProps {
  component?: ReactNode;
  to?: string;
  state?: object;
}

interface ListItemStyleProps extends StyleProps {
  component?: ReactNode;
  to?: string;
  end?: boolean;
  state?: object;
}

const ListItemStyle = styled(ListItemButton)<ListItemStyleProps>(({ theme }) => ({
  ...theme.typography.body2,
  height: ITEM_SIZE,
  textTransform: 'capitalize',
  paddingLeft: theme.spacing(PADDING),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary
}));

const StyledJoinFreeButton = styled(Button)<StyledButtonProps>(({ theme }) => ({
  marginRight: theme.spacing(3),
  borderRadius: theme.spacing(2),
  fontWeight: 400,
  fontSize: 14,
  lineHeight: '16px',
  padding: '10px 15px',
  [theme.breakpoints.down('sm')]: {
    marginRight: 0
  }
}));

// ----------------------------------------------------------------------

type MenuMobileItemProps = {
  item: MenuItemProps;
  isOpen: boolean;
  onOpen: VoidFunction;
  handleDrawerClose: () => void;
};

function MenuMobileItem({ item, isOpen, onOpen, handleDrawerClose }: MenuMobileItemProps) {
  const { title, path, icon, children, redirectURL } = item;
  const pathParams = useParams();
  const { isAuthenticated, logout } = useAuth();

  const getRedirectURL = (url: string) => {
    let rtnURL = url;
    Object.keys(pathParams).forEach((item: string) => {
      const paramVal = pathParams[item] || '';
      rtnURL = rtnURL.replace(`:${item}`, paramVal);
    });
    return rtnURL;
  };

  const navItemClick = () => {
    if (title === 'Sign out') {
      logout();
      handleDrawerClose();
    }
  };

  const menuItemClick = (Mitem: { [key: string]: any }) => {
    navItemClick();
    if (Mitem.title === 'Sign out') {
      trackEvent('click_sign_out', {
        path: window.location.href,
        source: 'navbar'
      });
    }
    if (
      window.location.href.includes('/insidertrading/profile') &&
      Mitem.trackingInsiderEventName
    ) {
      trackEvent(Mitem.trackingInsiderEventName, {
        ScreenName: 'InsiderTrading',
        path: window.location.href
      });
    } else {
      stockBtnTabEventTracking(
        'Mobile Menu',
        '',
        event_types.btnClick,
        `${Mitem.title}`,
        `${Mitem.title}`,
        window.location.href
      );
    }
  };

  if (children) {
    return (
      <>
        <ListItemStyle onClick={onOpen}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText disableTypography primary={title} />
          <Box
            component={Icon}
            icon={isOpen ? arrowIosDownwardFill : arrowIosForwardFill}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <Box sx={{ display: 'flex', flexDirection: 'column-reverse' }}>
            <NavSection
              navConfig={children}
              sx={{
                '& .MuiList-root:last-of-type .MuiListItemButton-root': {
                  height: 200,
                  backgroundSize: '92%',
                  backgroundPosition: 'center',
                  bgcolor: 'background.neutral',
                  backgroundRepeat: 'no-repeat',
                  backgroundImage: 'url(/static/illustrations/illustration_dashboard.png)',
                  '& > *:not(.MuiTouchRipple-root)': { display: 'none' }
                },
                '& .MuiListSubheader-root': {
                  pl: PADDING,
                  display: 'flex',
                  alignItems: 'center',
                  '&:before': {
                    ml: '6px',
                    mr: '22px',
                    width: 8,
                    height: 2,
                    content: "''",
                    borderRadius: 2,
                    bgcolor: 'currentColor'
                  }
                },
                '& .MuiListItemButton-root': {
                  pl: PADDING,
                  '&:before': { display: 'none' },
                  '&.active': { color: 'primary.main', bgcolor: 'transparent' }
                },
                '& .MuiListItemIcon-root': {
                  width: ICON_SIZE,
                  height: ICON_SIZE,
                  '&:before': {
                    width: 4,
                    height: 4,
                    content: "''",
                    borderRadius: '50%',
                    bgcolor: 'currentColor'
                  }
                }
              }}
            />
          </Box>
        </Collapse>
      </>
    );
  }

  if (title === 'Documentation') {
    return (
      <ListItemStyle
        href={path}
        target="_blank"
        component={Link}
        onClick={() => {
          stockBtnTabEventTracking(
            'Mobile Menu',
            '',
            event_types.btnClick,
            `${title}`,
            `${title}`,
            window.location.href
          );
        }}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText disableTypography primary={title} />
      </ListItemStyle>
    );
  }

  if (title === 'Sign in') {
    return isAuthenticated ? null : (
      <ListItemStyle
        sx={{
          '&.active': {
            color: 'primary.main',
            fontWeight: 'fontWeightMedium',
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
          }
        }}
        onClick={() => {
          dispatch(setShowLoginModal());
          trackEvent('click_sign_in', {
            path: window.location.href,
            source: 'navbar'
          });
          // stockBtnTabEventTracking(
          //   'Mobile Menu',
          //   '',
          //   event_types.btnClick,
          //   `${title}`,
          //   `${title}`,
          //   window.location.href
          // );
        }}
      >
        {title}
      </ListItemStyle>
    );
  }

  if (title === 'Blog') {
    return (
      <ListItemStyle
        href={path}
        target="_blank"
        component={Link}
        sx={{
          '&.active': {
            color: 'primary.main',
            fontWeight: 'fontWeightMedium',
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
          }
        }}
        onClick={() => {
          if (
            window.location.href.includes('/insidertrading/profile') &&
            item.trackingInsiderEventName
          ) {
            trackEvent(item.trackingInsiderEventName, {
              ScreenName: 'InsiderTrading',
              path: window.location.href
            });
          } else {
            stockBtnTabEventTracking(
              'Mobile Menu',
              '',
              event_types.btnClick,
              `${title}`,
              `${title}`,
              window.location.href
            );
          }
        }}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText disableTypography primary={title} />
      </ListItemStyle>
    );
  }

  return (
    <ListItemStyle
      to={path}
      component={RouterLink}
      end={path === '/'}
      sx={{
        '&.active': {
          color: 'primary.main',
          fontWeight: 'fontWeightMedium',
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
        }
      }}
      onClick={() => menuItemClick(item)}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText disableTypography primary={title} />
    </ListItemStyle>
  );
}

export default function MenuMobile({ isOffset, isHome, navConfig }: MenuProps) {
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const { isAuthenticated, user } = useAuth();
  const CTABtnState = {
    redirectURL: '/stock/AAPL',
    actionType: 'profile',
    stockSymbol: '',
    landingCTA: true
  };

  useEffect(() => {
    if (drawerOpen) {
      handleDrawerClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const joinFreeBtnClick = () => {
    dispatch(setShowRegistrationModal());
    // if (window.location.href.includes('/insidertrading/profile')) {
    //   trackEvent('click_join_for_free', {
    //     ScreenName: 'InsiderTrading',
    //     path: window.location.href
    //   });
    // } else {
    //   stockBtnTabEventTracking(
    //     'Mobile Menu',
    //     '',
    //     event_types.btnClick,
    //     'Free Trial',
    //     'Free Trial',
    //     window.location.href
    //   );
    // }
    trackEvent('click_join_for_free', {
      ScreenName: 'InsiderTrading',
      path: window.location.href,
      source: 'navbar'
    });
  };

  const insiderState = {
    redirectURL: pathname
  };

  const usersHideEmailAlerts = [
    'haris.widjaja@gmail.com',
    'tahneetran3@gmail.com',
    'mconnolly9@gmail.com',
    'cherrypikky@gmail.com',
    'mcoron@gmail.com',
    'jingjingdu.cmu@gmail.com',
    'gfwv0@fthcapital.com',
    'dspencer5544@outlook.com',
    'amberabbaskhan12@gmail.com',
    'nozin@yandex.ru',
    'fabienfabien20633@gmail.com',
    'tterry911@yahoo.com',
    'gaspar_terrazas@hotmail.com',
    'activoenredes@gmail.com',
    'lingjian0315@163.com',
    'jafs03@gmail.com',
    'amulcotton816@gmail.com',
    'jaypretium@gmail.com',
    'paprotpaprocki@gmail.com',
    'willapthompson@gmail.com',
    'gil.zwick@gmail.com'
  ];

  return (
    <>
      {!isAuthenticated && (
        <StyledJoinFreeButton variant="contained" onClick={() => joinFreeBtnClick()}>
          Free Trial
        </StyledJoinFreeButton>
      )}
      <MIconButton
        onClick={handleDrawerOpen}
        sx={{
          ml: 1,
          color: 'text.primary',
          pr: 0
        }}
      >
        <Icon icon="entypo:dots-three-vertical" />
      </MIconButton>

      <Drawer
        open={drawerOpen}
        onClose={handleDrawerClose}
        ModalProps={{ keepMounted: true }}
        PaperProps={{ sx: { pb: 5, width: 260 } }}
      >
        <Scrollbar>
          <Link component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
            <Logo sx={{ mx: PADDING, my: 3 }} />
            <Typography variant="h3" color={'black'} sx={{ mt: 1, alignSelf: 'center' }}>
              TradingTown
            </Typography>
          </Link>

          <List disablePadding>
            {navConfig
              .filter(
                (link: any) =>
                  link.isAuthenticated == isAuthenticated || link.isAuthenticated == undefined
              )
              .map((link: any) => {
                if (
                  link.title == 'Email Alerts' &&
                  isAuthenticated &&
                  user &&
                  usersHideEmailAlerts.indexOf(user.email) > -1
                ) {
                  return;
                }
                return (
                  <MenuMobileItem
                    key={link.title}
                    item={link}
                    isOpen={open}
                    onOpen={handleOpen}
                    handleDrawerClose={handleDrawerClose}
                  />
                );
              })}
          </List>
        </Scrollbar>
      </Drawer>
    </>
  );
}
