// hooks
import useAuth from '../hooks/useAuth';
//
import { MAvatar } from './@material-extend';
import { MAvatarProps } from './@material-extend/MAvatar';
import createAvatar from '../utils/createAvatar';
import { ButtonTracking, stockBtnTabEventTracking, event_types } from './amplitute';
import { useParams } from 'react-router';
import { useMemo } from 'react';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: MAvatarProps) {
  const { user } = useAuth();
  const { stockSymbol: stockSym = '' } = useParams();
  const stockSymbol = useMemo(() => stockSym, [stockSym]);

  return (
    <MAvatar
      src={user?.photoURL}
      alt={user?.displayName}
      color={user?.photoURL ? 'default' : createAvatar(user?.name).color}
      {...other}
      onClick={() => {
        // ButtonTracking(
        //   `Profile Menu Click ${window.location.pathname}`,
        //   window.location.href,
        //   window.location.pathname
        // );
        stockBtnTabEventTracking(
          'Profile Menu',
          user?.name,
          event_types.menuClick,
          'New Updates',
          user?.name,
          window.location.href
        );
      }}
    >
      {createAvatar(user?.name).name}
    </MAvatar>
  );
}
