import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
// import { CategoryManager } from '../../@types/category';

// ----------------------------------------------------------------------

type TickerState = {
  isLoading: boolean;
  error: boolean;
  tickerList: any[];
};

const initialState: TickerState = {
  isLoading: false,
  error: false,
  tickerList: []
};

const slice = createSlice({
  name: 'ticker',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET TICKER LIST
    getTickerListSuccess(state, action) {
      state.isLoading = false;
      state.tickerList = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions

// ----------------------------------------------------------------------

export function getTickerList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/v1/stocks/trending');
      // const response = { data: { results: [] } };
      dispatch(slice.actions.getTickerListSuccess(response.data.trendingStocks));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
