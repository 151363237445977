import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------
interface dataInput {
  id: number;
  ticker_title: string;
  // time: string;
  timestamp_: string;
  expiration: string;
  strikeVal: string;
  volume?: string;
  price: string;
  open_interest: string;
  last_price: string;
  put_call: string;
  type_: string;
  createdAt: string;
}

type TickerState = {
  isLoading: boolean;
  error: boolean;
  stock: any;
  similar: any;
  quote: any;
  news: any;
  newsNextURL: any;
  priceTarget: any;
  rating: any;
  stockSearchList: any;
  optionsList: Array<dataInput>;
};

const initialState: TickerState = {
  isLoading: false,
  error: false,
  stock: null,
  similar: [],
  quote: {},
  news: [],
  newsNextURL: null,
  priceTarget: null,
  rating: {},
  stockSearchList: [],
  optionsList: []
};

const slice = createSlice({
  name: 'stock',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // RESET STATE
    resetState(state) {
      state.isLoading = false;
      state.error = false;
      state.stock = null;
      state.similar = [];
      state.quote = {};
      state.news = [];
      state.newsNextURL = null;
      state.priceTarget = null;
      state.rating = {};
      state.optionsList = [];
    },

    // GET TICKER LIST
    getStockDetailsSuccess(state, action) {
      state.isLoading = false;
      state.stock = action.payload;
    },

    // GET TICKER SIMILAR
    getStockSimilarSuccess(state, action) {
      state.isLoading = false;
      state.similar = action.payload;
    },

    // GET TICKER QUOTE
    getStockQuoteSuccess(state, action) {
      state.isLoading = false;
      state.quote = action.payload;
    },

    // GET TICKER News LIST
    getStockNewsSuccess(state, action) {
      state.isLoading = false;
      state.news = action.payload.results;
      state.newsNextURL = action.payload.next_url;
    },

    getNextStockNewsSuccess(state, action) {
      state.isLoading = false;
      state.news = [...state.news, ...action.payload.results];
      state.newsNextURL = action.payload.next_url;
    },

    // GET Price Target
    getPriceTargetSuccess(state, action) {
      state.isLoading = false;
      state.priceTarget = action.payload;
    },

    // GET Rating
    getStockRatingSuccess(state, action) {
      state.isLoading = false;
      state.rating = action.payload;
    },
    getStockSearchListSuccess(state, action) {
      state.isLoading = false;
      state.stockSearchList = action.payload;
    },
    getSuccessOptionActivities(state, action) {
      state.isLoading = false;
      state.optionsList = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions

// ----------------------------------------------------------------------

export function resetState() {
  return async () => {
    dispatch(slice.actions.resetState());
  };
}

export function getStockDetails(stockSymbol: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1/stocks/ticker-detail/${stockSymbol}`);
      if (response.data.status === 404) {
        return response.data.data;
      } else {
        dispatch(slice.actions.getStockDetailsSuccess(response.data.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getStockSimilar(stockSymbol: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1/stocks/ticker-similar/${stockSymbol}`);
      if (response.data.status === 404) {
        return response.data.data;
      } else {
        dispatch(slice.actions.getStockSimilarSuccess(response.data.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getStockQuote(stockSymbol: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1/stocks/ticker-quote/${stockSymbol}`);
      dispatch(slice.actions.getStockQuoteSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getStockRating(stockSymbol: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1/stocks/ticker-rating/${stockSymbol}`);
      dispatch(slice.actions.getStockRatingSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getStockNews(stockSymbol: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1/stocks/ticker-news/${stockSymbol}`);
      dispatch(slice.actions.getStockNewsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getNextStockNews(newsNextURL: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/api/v1/stocks/ticker-news/next`, { newsNextURL });
      dispatch(slice.actions.getNextStockNewsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPriceTarget(stockSymbol: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1/stocks/price-target/${stockSymbol}`);
      if (response.data.status === 404) {
        return response.data;
      } else {
        dispatch(slice.actions.getPriceTargetSuccess(response.data.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function searchStocks(searchText: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/v1/stocks/searchStocks', { searchText });
      dispatch(slice.actions.getStockSearchListSuccess(response.data.stocks));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOptionActivities() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1/optionsactivity/getAll`);
      dispatch(slice.actions.getSuccessOptionActivities(response?.data?.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOptionByTag(ticker: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1/optionsactivity/ticker-options/${ticker}`);
      dispatch(slice.actions.getSuccessOptionActivities(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
