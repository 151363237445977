import { Icon } from '@iconify/react';
import googleFill from '@iconify/icons-eva/google-fill';
import twitterFill from '@iconify/icons-eva/twitter-fill';
import facebookFill from '@iconify/icons-eva/facebook-fill';
import linkedinFill from '@iconify/icons-eva/linkedin-fill';
import youtubeFill from '@iconify/icons-mingcute/youtube-fill';
import tiktokFill from '@iconify/icons-mingcute/tiktok-fill';
import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink, useParams } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Grid, Link, Divider, Container, Typography, IconButton, Stack } from '@mui/material';
// routes
import { PATH_PAGE } from '../../routes/paths';
//
import Logo from '../../components/Logo';
import useAuth from 'hooks/useAuth';
import { useEffect, useMemo } from 'react';
import { init } from '@amplitude/analytics-browser';
import { API_KEY } from 'utils/apiKeyAmplitute';
import { socialBtnTracking, stockBtnTabEventTracking, event_types } from 'components/amplitute';
import { RootState, useSelector } from '../../redux/store';

// ----------------------------------------------------------------------

const SOCIALS = [
  // {
  //   name: 'FaceBook',
  //   icon: facebookFill,
  //   link: ''
  // },
  // {
  //   name: 'Google',
  //   icon: googleFill,
  //   link: ''
  // },
  // {
  //   name: 'Linkedin',
  //   icon: linkedinFill,
  //   link: ''
  // },
  {
    name: 'Twitter',
    icon: twitterFill,
    link: 'https://twitter.com/Insider_WTrade'
  },
  {
    name: 'Youtube',
    icon: youtubeFill,
    link: 'https://www.youtube.com/@InsiderWTrade'
  },
  {
    name: 'Tiktok',
    icon: tiktokFill,
    link: 'https://www.tiktok.com/@tradingtown.space?_t=8YSnitgeKis&_r=1'
  }
];

// const LINKS = [
//   {
//     headline: 'Trading Town',
//     children: [
//       { name: 'About us', href: PATH_PAGE.about },
//       { name: 'Contact us', href: PATH_PAGE.contact },
//       { name: 'FAQs', href: PATH_PAGE.faqs }
//     ]
//   },
//   {
//     headline: 'Legal',
//     children: [
//       { name: 'Terms and Condition', href: '#' },
//       { name: 'Privacy Policy', href: '#' }
//     ]
//   },
//   {
//     headline: 'Contact',
//     children: [
//       { name: 'tradingtownofcmu@gmail.com', href: '#' },
//       { name: 'Los Angeles, USA', href: '#' }
//     ]
//   }
// ];
const LINKS = [
  {
    headline: 'Trading Town',
    children: [{ name: 'Contact us', href: '#', trackingEventName: 'click_contact_us' }]
  },
  {
    headline: 'Legal',
    children: [
      { name: 'Terms of Service', href: PATH_PAGE.terms, trackingEventName: null },
      { name: 'Privacy Policy', href: PATH_PAGE.policy, trackingEventName: null }
    ]
  }
];
const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  const { user } = useAuth();
  const { stockSymbol: stockSym = '' } = useParams();
  const stockSymbol = useMemo(() => stockSym, [stockSym]);
  const { isAuthenticated } = useAuth();
  const { theme: pageTheme } = useSelector((state: RootState) => state.theme);
  useEffect(() => {
    init(API_KEY, user?.email ? user?.email : 'N/A');
  }, []);

  const trackSocialBtnClick = (social: { [key: string]: any }) => {
    if (window.location.href.includes('/insidertrading')) {
      socialBtnTracking(
        'Footer',
        '',
        event_types.clickSocial,
        `${social.name}`,
        `${social.name}`,
        (window.location.href = social.link),
        `${social.name}`
      );
    } else {
      stockBtnTabEventTracking(
        'Footer',
        '',
        event_types.clickSocial,
        `${social.name}`,
        `${social.name}`,
        (window.location.href = social.link)
      );
    }
  };

  const trackFooterLink = (headline: string, link: { [key: string]: any }) => {
    const event = window.location.href.includes('/insidertrading')
      ? link.trackingEventName
      : event_types.tabClick;
    stockBtnTabEventTracking(
      'Footer',
      '',
      event,
      `${link.name}/ ${headline}`,
      `${link.name}/ ${headline}`,
      window.location.href
    );
    if (link.name === 'Contact us') {
      window.open(
        `mailto:support@tradingtown.space?subject=[Subject]&body=` +
          `Current Page: ${window.location.pathname}` +
          '%0D%0A%0D%0ATime: ' +
          `${new Date().toUTCString()}` +
          '%0D%0A%0D%0ALogin Status: ' +
          `${isAuthenticated}` +
          `If you've encountered an issue, please describe the issue below:%0D%0A%0D%0A_____________%0D%0A%0D%0AHow has our platform helped you in your investment research?%0D%0A%0D%0A____________%0D%0A%0D%0ADo you have any other suggestions or feedback? Which other features would you like to see on our platform?%0D%0A%0D%0A`
      );
    }
  };
  return (
    <RootStyle sx={pageTheme === 'bluebg' ? { backgroundColor: '#F6F9FE;' } : {}}>
      <Divider />
      <Container maxWidth="lg" sx={{ pt: 10 }}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          <Grid item xs={12} sx={{ mb: 3 }}>
            <ScrollLink
              to="move_top"
              spy
              smooth
              onClick={() => {
                stockBtnTabEventTracking(
                  'Footer',
                  '',
                  event_types.btnClick,
                  `Footer Logo`,
                  'Footer Logo',
                  window.location.href
                );
              }}
            >
              <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} />
            </ScrollLink>
          </Grid>
          <Grid item xs={8} md={3}>
            <Typography variant="body2" sx={{ pr: { md: 5 } }}>
              The starting point for your trading with Trading Town, built to provide latest
              analytics.
            </Typography>

            <Stack
              spacing={1.5}
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{ mt: 5, mb: { xs: 5, md: 0 } }}
            >
              {SOCIALS.map((social) => (
                <IconButton
                  key={social.name}
                  color="primary"
                  sx={{ p: 1 }}
                  onClick={() => {
                    trackSocialBtnClick(social);
                  }}
                >
                  <Icon icon={social.icon} width={16} height={16} />
                </IconButton>
              ))}
            </Stack>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack
              spacing={5}
              direction={{ xs: 'column', md: 'row' }}
              justifyContent="space-between"
            >
              {LINKS.map((list) => {
                const { headline, children } = list;
                return (
                  <Stack key={headline} spacing={2}>
                    <Typography component="p" variant="overline">
                      {headline}
                    </Typography>
                    {children.map((link) => (
                      <Link
                        to={link.href}
                        key={link.name}
                        color="inherit"
                        variant="body2"
                        component={RouterLink}
                        sx={{ display: 'block' }}
                        onClick={() => {
                          trackFooterLink(headline, link);
                        }}
                      >
                        {link.name}
                      </Link>
                    ))}
                  </Stack>
                );
              })}
            </Stack>
          </Grid>
        </Grid>

        <Typography
          component="p"
          variant="body2"
          sx={{
            mt: 10,
            pb: 5,
            fontSize: 13,
            textAlign: { xs: 'center', md: 'left' }
          }}
        >
          © 2024. All rights reserved
        </Typography>
      </Container>
    </RootStyle>
  );
}
