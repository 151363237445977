import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Typography, AppBar, Toolbar, Container } from '@mui/material';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
// components
import Logo from '../../components/Logo';
import { MHidden } from '../../components/@material-extend';
import MenuDesktop from './MenuDesktop';
import MenuMobile from './MenuMobile';
import navConfig from './MenuConfig';
import Searchbar from 'layouts/dashboard/Searchbar';
import useSettings from 'hooks/useSettings';
import { stockBtnTabEventTracking, event_types } from 'components/amplitute';
import useAuth from 'hooks/useAuth';
import { useEffect } from 'react';
import { init } from '@amplitude/analytics-browser';
import { API_KEY } from 'utils/apiKeyAmplitute';
import searchBarConfig from '../../config/search-bar.config';
import { RootState, useSelector } from '../../redux/store';
import { matchPath } from 'react-router';
import LoginRegisterFormModal from '../../components/authentication/login-registration-modal';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 88;

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: {
    height: APP_BAR_DESKTOP
  }
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8
}));

// ----------------------------------------------------------------------

export type MenuItemProps = {
  title: string;
  path: string;
  icon?: JSX.Element;
  to?: string;
  isAuthenticated?: boolean;
  trackingInsiderEventName?: string;
  redirectURL?: string;
  menuItemOnClick?: () => void;
  children?: {
    subheader: string;
    items: {
      title: string;
      path: string;
    }[];
  }[];
};

export type MenuProps = {
  isOffset: boolean;
  isHome: boolean;
  navConfig: MenuItemProps[];
};

export default function MainNavbar() {
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';
  const isOffset = useOffSetTop(100);
  var { pathname } = useLocation();
  const isHome = pathname === '/';
  // grab correct path for insider profiles (throw away insider id)
  //pathname = pathname?.includes('/insidertrading/profile') ? '/insidertrading/profile' : pathname;
  const showSearchBar = searchBarConfig[pathname] || pathname?.includes('/stock');
  let { user } = useAuth();
  const { theme: pageTheme } = useSelector((state: RootState) => state.theme);
  const { showLoginRegistrationModal } = useSelector((state: RootState) => state.user);
  const getRouteConfig = () => {
    const matchConfigKey = Object.keys(navConfig).find((rkey: string) => {
      return matchPath(rkey, pathname);
    });
    return matchConfigKey
      ? navConfig[matchConfigKey]
        ? navConfig[matchConfigKey]
        : navConfig.default
      : navConfig.default;
  };
  const pageNavItems = getRouteConfig();
  useEffect(() => {
    init(API_KEY, user?.email ? user?.email : 'N/A');
  }, []);

  return (
    <AppBar sx={{ boxShadow: 0, bgcolor: pageTheme === 'bluebg' ? '#F6F9FE' : 'white' }}>
      <ToolbarStyle
        disableGutters
        sx={{
          ...(isOffset && {
            bgcolor: pageTheme === 'bluebg' ? '#F6F9FE' : 'background.default',
            height: { md: APP_BAR_DESKTOP - 16 }
          })
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          <RouterLink
            to="/"
            onClick={() => {
              // TabTracking(
              //   `Logo Click ${window.location.pathname}`,
              //   window.location.href,
              //   window.location.pathname
              // );
              stockBtnTabEventTracking(
                'Dashboard',
                '',
                event_types.btnClick,
                'Logo Click',
                'NavigationBar',
                window.location.href
              );
            }}
          >
            <Logo />
          </RouterLink>

          <Typography
            variant="h3"
            // color={isLight ? 'black' : 'white'}
            color="black"
            // sx={{ ml: 3, mt: 1 }}
            sx={{
              ...(isOffset && {
                color: 'black'
              }),
              ml: {
                xs: 1,
                sm: 3
              },
              mt: 1,
              fontSize: {
                xs: '1.125rem',
                sm: '1.5rem'
              }
            }}
          >
            TradingTown
          </Typography>

          {showSearchBar ? <Searchbar /> : null}

          <Box sx={{ flexGrow: 1 }} />

          <MHidden width="mdDown">
            <MenuDesktop isOffset={isOffset} isHome={isHome} navConfig={pageNavItems} />
          </MHidden>

          {/* <Button
            variant="contained"
            target="_blank"
            href="https://material-ui.com/store/items/minimal-dashboard/"
          >
            Purchase Now
          </Button> */}
          <MHidden width="mdUp">
            <MenuMobile isOffset={isOffset} isHome={isHome} navConfig={pageNavItems} />
          </MHidden>
        </Container>
      </ToolbarStyle>

      {isOffset && <ToolbarShadowStyle />}
      {showLoginRegistrationModal && <LoginRegisterFormModal />}
    </AppBar>
  );
}
