import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import { PATH_DASHBOARD } from '../routes/paths';

import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// @ts-ignore
import { dispatch } from '../redux/store';
// utils
import axios from '../utils/axios';
import { CategoryManager } from '../@types/category';
// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: ReactNode;
};

type WatchState = {
  isLoading: boolean;
  error: boolean;
  watchList: any[];
  watchListOnlySymbol: any[];
};

const initialState: WatchState = {
  isLoading: false,
  error: false,
  watchList: [],
  watchListOnlySymbol: []
};

const slice = createSlice({
  name: 'watch',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET WATCH LIST
    getWatchListSuccess(state, action) {
      state.isLoading = false;
      state.watchList = action.payload;
    },
    getWatchListSorted(state, action) {
      state.isLoading = false;
      state.watchList = action.payload;
    },
    getWatchListOnlySymbolSuccess(state, action) {
      state.isLoading = false;
      state.watchListOnlySymbol = action.payload;
    },
    addedToWatchList(state, action) {
      state.isLoading = false;
      state.watchListOnlySymbol = [...state.watchListOnlySymbol, action.payload];
    },
    removeFromWatchList(state, action) {
      state.isLoading = false;
      state.watchListOnlySymbol = action.payload;
    }
  }
});

export default function GuestGuard({ children }: GuestGuardProps) {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  if (isAuthenticated) {
    let pathname = '/';
    // @ts-ignore
    if (location?.state?.redirectURL && location?.state?.actionType == 'optact') {
      // @ts-ignore
      pathname = location?.state?.redirectURL + '/' + location?.state?.stockSymbol;
    }
    // @ts-ignore
    if (location?.state?.redirectURL && location?.state?.actionType == 'profile') {
      // @ts-ignore
      pathname = location?.state?.redirectURL + '/' + location?.state?.stockSymbol;
    }
    // @ts-ignore
    if (location?.state?.redirectURL && location?.state?.actionType == 'follow') {
      // @ts-ignore
      let sym = location?.state?.stockSymbol;
      // @ts-ignore
      let nam = location?.state?.stockName;
      // @ts-ignore
      let log = location?.state?.stockLogo;
      let data = {
        symbol: sym,
        name: nam,
        logo: log
      };
      dispatch(addToWatchList({ symbol: sym, name: nam, logo: log }));
      // @ts-ignore
      pathname = location?.state?.redirectURL + '/' + location?.state?.stockSymbol;
    }
    // @ts-ignore
    if (location?.state?.redirectURL && location?.state?.actionType == 'similar') {
      // @ts-ignore
      let sym = location?.state?.stockwishlistSymbol;
      let data = {
        symbol: sym
      };
      console.log(sym);
      dispatch(addToWatchList({ symbol: sym }));
      // @ts-ignore
      pathname = location?.state?.redirectURL + '/' + location?.state?.stockSymbol;
    }
    return <Navigate to={pathname} />;
  }

  return <>{children}</>;
}

export function addToWatchList(data: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/v1/watchlist/add', data);
      dispatch(slice.actions.getWatchListSuccess(response.data.watchList));
      dispatch(slice.actions.addedToWatchList({ symbol: data?.symbol }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
