import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

type AnalyticsState = {
  isLoading: boolean;
  error: boolean;
  counts: any;
};

const initialState: AnalyticsState = {
  isLoading: false,
  error: false,
  counts: {}
};

const slice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Get Success Analytics Count
    getSuccessAnalyticsCount(state, action) {
      state.isLoading = false;
      state.counts = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions

// ----------------------------------------------------------------------

export function getAnalyticsCount() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/v1/analytics/counts');
      dispatch(slice.actions.getSuccessAnalyticsCount(response.data.counts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
