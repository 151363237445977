import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
//
// material
import { useTheme, styled } from '@mui/material/styles';
import { Box, Typography, Tooltip, Card, CardHeader, Chip } from '@mui/material';
import { BaseOptionChart } from 'components/charts';
import moment from 'moment';
import InfoIcon from '@mui/icons-material/Info';
import { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
// redux
import { RootState, useDispatch, useSelector } from 'redux/store';

// ----------------------------------------------------------------------

const CHART_HEIGHT = 420;
const LEGEND_HEIGHT = 72;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(-5),
  marginBottom: theme.spacing(10),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible'
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important' as 'relative',
    // borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT}px) !important`
  }
}));

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    padding: 0
  }
}));

export default function PortfolioChart({ ratio }: any) {
  const theme = useTheme();

  const chartOptions = merge(BaseOptionChart(), {
    colors: [theme.palette.primary.main, '#419158'],
    chart: {
      type: 'line'
    },
    stroke: {
      width: [4, 4]
    },
    // stroke: { show: false },
    plotOptions: {},
    legend: {
      horizontalAlign: 'center'
    },
    // fill: {
    //   type: 'gradient',
    //   gradient: {
    //     shadeIntensity: 0.1,
    //     opacityFrom: 1,
    //     opacityTo: 0.7,
    //     stops: [0, 90, 100]
    //   }
    // },
    xaxis: {
      type: 'datetime',
      categories: ratio?.payload?.map((item: any) => moment(item?.timestamp).format('YYYY-MM-DD'))
    },
    yaxis: [
      {
        title: {
          text: 'Portfolio value ($)'
        },
        min: 5000,
        max: 75000,
        decimalsInFloat: 2
        // labels: {
        //   formatter: function (val: any) {
        //     return nFormatter(val);
        //   }
        // }
      }
    ],
    tooltip: {
      shared: true,
      intersect: false,
      fillSeriesColor: false,
      y: {
        formatter: function (value: number) {
          return '$' + new Intl.NumberFormat('en-US').format(value);
        }
      }
    }
  });

  return (
    <Card sx={{ height: '100%', width: '100%' }}>
      <CardHeader
        title={
          <Box>
            <Box display={'flex'} alignItems="center">
              <Typography component={'span'} variant="h4">
                Performance: Fund 560.42% vs Benchmark 88.86%
              </Typography>
            </Box>
          </Box>
        }
      />
      <ChartWrapperStyle dir="ltr" sx={{ marginLeft: '10px', marginRight: '10px' }}>
        <ReactApexChart
          type="line"
          series={[
            {
              name: 'Corporate Insider Trades Portfolio',
              type: 'line',
              data: ratio?.payload?.map((item: any) =>
                item?.portfolio_value ? item?.portfolio_value : 0
              )
            },
            {
              name: 'SPY',
              type: 'line',
              data: ratio?.payload?.map((item: any) => (item?.spy_value ? item?.spy_value : 0))
            }
          ]}
          options={chartOptions}
          height={420}
        />
      </ChartWrapperStyle>
    </Card>
  );
}

function LoopbackTooltip() {
  return (
    <Card>
      <Box sx={{ p: 2 }}>
        <Typography>
          <strong>Lookback </strong>
          <br />
          In trading, a lookback period is the number of periods of historical data used for
          observation and calculation. It is how far into the past a system looks when trying to
          calculate the variable under review.
        </Typography>
      </Box>
    </Card>
  );
}

function getYMinPrice(ratio: any) {
  let min = Math.min(...ratio?.data?.map((item: any) => item?.price));
  if (min >= 0) {
    min = (min * 90) / 100;
  } else {
    min = (min * 110) / 100;
  }
  return min;
}

function getYMaxPrice(ratio: any) {
  let max = Math.max(...ratio?.data?.map((item: any) => item?.price));
  if (max >= 0) {
    max = (max * 110) / 100;
  } else {
    max = (max * 90) / 100;
  }
  return max;
}

function getYMinRatio(ratio: any) {
  let min = Math.min(...ratio?.data?.map((item: any) => item?.ratio));
  if (min >= 0) {
    min = (min * 90) / 100;
  } else {
    min = (min * 110) / 100;
  }
  return min;
}

function getYMaxRatio(ratio: any) {
  let max = Math.max(...ratio?.data?.map((item: any) => item?.ratio));
  if (max >= 0) {
    max = (max * 110) / 100;
  } else {
    max = (max * 90) / 100;
  }
  return max;
}

function nFormatter(value: any) {
  var newValue = value;
  if (value >= 1000) {
    var suffixes = ['', 'K', 'M', 'B', 'T'];
    var suffixNum = Math.floor(('' + value).length / 3);
    var shortValue: any = '';
    for (var precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat(
        (suffixNum != 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(precision)
      );
      var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');
      if (dotLessShortValue.length <= 2) {
        break;
      }
    }
    if (shortValue % 1 != 0) shortValue = shortValue.toFixed(1);
    newValue = shortValue + suffixes[suffixNum];
  }
  return newValue;
}
