import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';
import { CategoryManager } from '../../@types/category';

// ----------------------------------------------------------------------

type WatchState = {
  isLoading: boolean;
  error: boolean;
  watchList: any[];
  watchListOnlySymbol: any[];
};

const initialState: WatchState = {
  isLoading: false,
  error: false,
  watchList: [],
  watchListOnlySymbol: []
};

const slice = createSlice({
  name: 'watch',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET WATCH LIST
    getWatchListSuccess(state, action) {
      state.isLoading = false;
      state.watchList = action.payload;
    },
    getWatchListSorted(state, action) {
      state.isLoading = false;
      state.watchList = action.payload;
    },
    getWatchListOnlySymbolSuccess(state, action) {
      state.isLoading = false;
      state.watchListOnlySymbol = action.payload;
    },
    addedToWatchList(state, action) {
      state.isLoading = false;
      state.watchListOnlySymbol = [...state.watchListOnlySymbol, action.payload];
    },
    removeFromWatchList(state, action) {
      state.isLoading = false;
      state.watchListOnlySymbol = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions

// ----------------------------------------------------------------------

export function getWatchList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/v1/watchlist/all');
      if (response.data.watchList) {
        dispatch(slice.actions.getWatchListSuccess(response.data.watchList));
      } else {
        dispatch(slice.actions.hasError({ message: 'Got Error' }));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getWatchListSortedDB(sortedList: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/v1/watchlist/sort', { sortedList: sortedList });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getWatchListSorted(list: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getWatchListSorted(list));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getWatchListOnlySymbol() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/v1/watchlist/onlysymbol');
      if (response.data.watchList) {
        dispatch(slice.actions.getWatchListOnlySymbolSuccess(response.data.watchList));
      } else {
        dispatch(slice.actions.hasError({ message: 'Got Error' }));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function verifyWatchSymbolExists(symbol: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1/watchlist/${symbol}`);
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function addToWatchList(data: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/v1/watchlist/add', data);
      dispatch(slice.actions.getWatchListSuccess(response.data.watchList));
      dispatch(slice.actions.addedToWatchList({ symbol: data?.symbol }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function removeFromWatchList(symbol: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/v1/watchlist/remove', { symbol });
      dispatch(slice.actions.getWatchListSuccess(response.data.watchList));
      let sym: any = [];
      response?.data?.watchList?.map((item: any) => sym.push(item.symbol));
      dispatch(slice.actions.removeFromWatchList(sym));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
