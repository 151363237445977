import * as React from 'react';
import Box from '@mui/material/Box';
import Backdrop from '@mui/material/Backdrop';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import EmailIcon from '@mui/icons-material/Email';
import Fab from '@mui/material/Fab';
import useAuth from 'hooks/useAuth';

const actions = [
  { icon: <EmailIcon />, name: 'If you`ve encountered an issue, please describe the issue below:' },
  { icon: <EmailIcon />, name: 'How has our platform helped you in your investment research?' },
  {
    icon: <EmailIcon />,
    name: 'Do you have any other suggestions or feedback? Which other features would you like to see on our platform?'
  }
];
const ContactFloatingButton = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { isAuthenticated } = useAuth();
  // const onclicks = (act) => {
  //   window.location.href = `
  //   mailto:tradingtownofcmu@gmail.com?subject=${act}
  //   "&body="what issue do you see?/nwhat do you think we should improve?/n
  //   what problem do you like to see our platform to handle?/n
  //   what feature do you like to see our platform to implement?`;
  // };
  return (
    <Box>
      <Fab
        color="primary"
        aria-label="add"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
        onClick={() => {
          window.open(
            `mailto:tradingtownofcmu@gmail.com?subject=[Subject]&body=` +
              `Current Page: ${window.location.pathname}` +
              '%0D%0A%0D%0ATime: ' +
              `${new Date().toUTCString()}` +
              '%0D%0A%0D%0ALogin Status: ' +
              `${isAuthenticated}` +
              `%0D%0A%0D%0AIf you've encountered an issue, please describe the issue below:%0D%0A%0D%0A_____________%0D%0A%0D%0AHow has our platform helped you in your investment research?%0D%0A%0D%0A____________%0D%0A%0D%0ADo you have any other suggestions or feedback? Which other features would you like to see on our platform?%0D%0A%0D%0A`
          );
        }}
      >
        <EmailIcon />
      </Fab>
    </Box>
  );
};

export default ContactFloatingButton;
