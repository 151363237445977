import { capitalCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Link, Alert, Tooltip, Container, Typography } from '@mui/material';
// routes
import { PATH_AUTH } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
// layouts
import AuthLayout from '../../layouts/AuthLayout';
// components
import Page from '../../components/Page';
import { MHidden } from '../../components/@material-extend';
import { LoginForm } from '../../components/authentication/login';
import { useEffect } from 'react';
import { init, setUserId } from '@amplitude/analytics-browser';
import { API_KEY } from 'utils/apiKeyAmplitute';
import { screenViewTracking, stockBtnTabEventTracking, event_types } from 'components/amplitute';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { user, method } = useAuth();
  const screenName = 'LoginPage';
  useEffect(() => {
    init(API_KEY, user?.email ? user?.email : 'N/A');
    setUserId('N/A');
    screenViewTracking(
      screenName,
      event_types.screenView,
      window.location.href,
      window.location.pathname
    );
  }, []);
  return (
    <RootStyle title="Login | Trading Town">
      <AuthLayout>
        Don’t have an account? &nbsp;
        <Link
          onClick={() => {
            // ButtonTracking(
            //   `Get started Button Click ${window.location.pathname}`,
            //   window.location.href,
            //   window.location.pathname
            // );
            stockBtnTabEventTracking(
              'Login',
              '',
              event_types.btnClick,
              `Get started Button`,
              `Authentication`,
              window.location.href
            );
          }}
          variant="subtitle2"
          component={RouterLink}
          to={PATH_AUTH.register}
        >
          Get started
        </Link>
      </AuthLayout>

      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Hi, Welcome Back
          </Typography>
          <img src="/static/illustrations/illustration_login.png" alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h4" gutterBottom>
                Sign in to Trading Town
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
            </Box>
          </Stack>

          <LoginForm />

          <MHidden width="smUp">
            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
              Don’t have an account?&nbsp;
              <Link
                onClick={() => {
                  // ButtonTracking(
                  //   `Get started Button Click ${window.location.pathname}`,
                  //   window.location.href,
                  //   window.location.pathname
                  // );
                  stockBtnTabEventTracking(
                    'Login',
                    '',
                    event_types.btnClick,
                    `Register Button`,
                    `Authentication`,
                    window.location.href
                  );
                }}
                variant="subtitle2"
                component={RouterLink}
                to={PATH_AUTH.register}
              >
                Get started
              </Link>
            </Typography>
          </MHidden>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
