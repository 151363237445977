import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

type FundamentalsState = {
  isLoading: boolean;
  error: boolean;
  eps: any;
  cashflow: any;
  peratio: any;
};

const initialState: FundamentalsState = {
  isLoading: false,
  error: false,
  eps: [],
  cashflow: null,
  peratio: null
};

const slice = createSlice({
  name: 'stockfundamentals',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getSuccessEPS(state, action) {
      state.isLoading = false;
      state.eps = action.payload;
    },

    getSuccessCashflow(state, action) {
      state.isLoading = false;
      state.cashflow = action.payload;
    },

    getSuccessPEratio(state, action) {
      state.isLoading = false;
      state.peratio = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions

// ----------------------------------------------------------------------

export function getEPS(ticker: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1/stocks/fundamentals/eps/${ticker}`);
      dispatch(slice.actions.getSuccessEPS(response?.data?.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCashflow(ticker: string, duration: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/v1/stocks/fundamentals/cashflow/${ticker}/${duration}`
      );
      dispatch(slice.actions.getSuccessCashflow(response?.data?.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPEratio(ticker: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1/stocks/fundamentals/peratio/${ticker}`);
      dispatch(slice.actions.getSuccessPEratio(response?.data?.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
