import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';

// redux
import { useDispatch, useSelector } from 'redux/store';

// ----------------------------------------------------------------------
interface dataInput {
  id: number;
  ticker_title: string;
  // time: string;
  expiration: string;
  strikeVal: string;
  volume?: string;
  price: string;
  open_interest: string;
  last_price: string;
  put_call: string;
  type_: string;
  createdAt: string;
}

type OptionsActivityState = {
  isLoading: boolean;
  error: boolean;
  data: Array<dataInput>;
  ratio: any;
};

const initialState: OptionsActivityState = {
  isLoading: false,
  error: false,
  data: [],
  ratio: null
};

const slice = createSlice({
  name: 'optionsActivity',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getSuccessOptionActivities(state, action) {
      state.isLoading = false;
      state.data = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions

// ----------------------------------------------------------------------

export function getOptionActivities() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1/optionsactivity/getAll`);
      dispatch(slice.actions.getSuccessOptionActivities(response?.data?.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOptionByTag(ticker: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1/optionsactivity/ticker-options/${ticker}`);
      dispatch(slice.actions.getSuccessOptionActivities(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
