import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

type TechnicalAnalysisState = {
  isLoading: boolean;
  error: boolean;
  movingaverage: any;
  relativestrengthindex: any;
  currentrelativestrengthindex: any;
  lastoccurence: any;
  daysdiffernece: any;
  lastcheck: any;
  showbars: any;
  lastoccurencemovingaverage: any;
  daysdiffernecemovingaverage: any;
};

const initialState: TechnicalAnalysisState = {
  isLoading: false,
  error: false,
  movingaverage: null,
  relativestrengthindex: null,
  currentrelativestrengthindex: null,
  lastoccurence: null,
  daysdiffernece: null,
  lastcheck: null,
  showbars: null,
  lastoccurencemovingaverage: null,
  daysdiffernecemovingaverage: null
};

const slice = createSlice({
  name: 'stocktechnicalanalysis',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    reset(state, action) {
      state.isLoading = false;
      state.movingaverage = null;
      state.relativestrengthindex = null;
      state.currentrelativestrengthindex = null;
      state.lastoccurence = null;
      state.daysdiffernece = null;
      state.lastcheck = null;
      state.showbars = null;
      state.lastoccurencemovingaverage = null;
      state.daysdiffernecemovingaverage = null;
    },

    getSuccessMovingAverage(state, action) {
      state.isLoading = false;
      state.movingaverage = action.payload.data;
      state.lastoccurencemovingaverage = action.payload.lastoccurencemovingaverage;
      state.daysdiffernecemovingaverage = action.payload.daysdiffernecemovingaverage;
    },

    getSuccessRelativeStrengthIndex(state, action) {
      console.log(action.payload);
      state.isLoading = false;
      state.relativestrengthindex = action.payload.rsi;
      state.currentrelativestrengthindex = action.payload.currentRsi;
      state.lastoccurence = action.payload.lastOccurence;
      state.daysdiffernece = action.payload.daysDiffernece;
      state.lastcheck = action.payload.lastCheck;
      state.showbars = action.payload.showbars;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions

// ----------------------------------------------------------------------

export function resetState() {
  return async () => {
    dispatch(slice.actions.reset({}));
  };
}

export function getMovingAverage(ticker: string, direction: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/v1/stocks/technicalanalysis/movingaverage/${ticker}/${direction}`
      );
      dispatch(slice.actions.getSuccessMovingAverage(response?.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRelativeStrengthIndex(ticker: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/v1/stocks/technicalanalysis/relativestrengthindex/${ticker}`
      );
      dispatch(slice.actions.getSuccessRelativeStrengthIndex(response?.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
