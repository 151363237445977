import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

type InsidersState = {
  isLoading: boolean;
  error: boolean;
  insidersList: any;
  insiderProfile: any;
  starRatingCutOff: any;
  algoTradingChartData: any;
  algoTradingActivities: any;
};

const initialState: InsidersState = {
  isLoading: false,
  error: false,
  insidersList: [],
  insiderProfile: {},
  starRatingCutOff: {},
  algoTradingChartData: null,
  // must be null! Otherwise, the chart will try to render when this is still null
  algoTradingActivities: null
};

const slice = createSlice({
  name: 'insiders',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // RESET STATE
    resetState(state) {
      state.isLoading = false;
      state.error = false;
      state.insidersList = [];
      state.insiderProfile = {};
    },

    getInsidersListSuccess(state, action) {
      state.isLoading = false;
      state.error = false;
      state.insidersList = action.payload;
    },

    getInsidersProfile(state, action) {
      state.isLoading = false;
      state.error = false;
      state.insiderProfile = action.payload;
    },
    getStarRatingCutOff(state, action) {
      state.starRatingCutOff = action.payload;
    },
    getAlgoTradingChartData(state, action) {
      state.isLoading = false;
      state.algoTradingChartData = action;
    },
    getAlgoTradingActivitiesData(state, action) {
      state.isLoading = false;
      state.algoTradingActivities = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export function resetState() {
  return async () => {
    dispatch(slice.actions.resetState());
  };
}

export function getAllInsiders(filters: { [index: string]: string | null }) {
  const queryParams = Object.keys(filters).map((item) => `${item}=${filters[item]}`);
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/v1/all-insiders?${queryParams.join('&')}`);
      dispatch(slice.actions.getInsidersListSuccess(response?.data));
    } catch (error) {
      dispatch(slice.actions.hasError({ error: true }));
    }
  };
}

export function getInsiderProfile(profileId: string, startDate: string, endDate: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/v1/all-insiders/profile/${profileId}?startdate=${startDate}&enddate=${endDate}`
      );
      dispatch(slice.actions.getInsidersProfile(response?.data));
    } catch (error) {
      dispatch(slice.actions.hasError({ error: true }));
    }
  };
}

export function getStarRatingCutoff() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/star_rating_cutoff`);
      dispatch(slice.actions.getStarRatingCutOff(response));
    } catch (error) {}
  };
}

export function getAlgoTradingChartData() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch('/static/insidertrading/algo_trading_chart_data.json');
      const data = await response.json();
      dispatch(slice.actions.getAlgoTradingChartData(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAlgoTradingActivitiesData() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await fetch('/static/insidertrading/algo_trading_activities.json');
      const data = await response.json();
      dispatch(slice.actions.getAlgoTradingActivitiesData(data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
