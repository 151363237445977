import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// ----------------------------------------------------------------------

type ThemeState = {
  theme: string;
};

const initialState: ThemeState = {
  theme: ''
};

const slice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setTheme(state, action) {
      state.theme = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export const { setTheme } = slice.actions;
