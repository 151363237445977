import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';

// redux
import { useDispatch, useSelector } from 'redux/store';
type Preference = {
  label: string;
  identifier: string;
};

type IniState = {
  isLoading: boolean;
  error: string;
  data: any;
};

const initialState: IniState = {
  isLoading: false,
  error: '',
  data: {}
};

const slice = createSlice({
  name: 'emailPreferences',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    successPreferences(state, action) {
      state.isLoading = false;
      state.data = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions

// ----------------------------------------------------------------------

export function getPreferencesList(encId?: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const url = encId ? `/email-preferences/${encId}` : `/email-preferences`;
      const response = await axios.get(url);
      dispatch(slice.actions.successPreferences(response?.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
