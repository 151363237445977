import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useDispatch } from '../../redux/store';
import { getPreferencesList } from '../../redux/slices/email-preferences';
import { ENABLE_TOKENIZED_PATH } from '../../config/email-preferences';
import { savePreferences, resetPreferenceSuccess } from '../../redux/slices/user';
import { RootState, useSelector } from '../../redux/store';
import { setShowLoginModalWithoutClose } from '../../redux/slices/user';
import closeFill from '@iconify/icons-eva/close-fill';
import useAuth from 'hooks/useAuth';
import {
  Container,
  CircularProgress,
  Button,
  Backdrop,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import Page from '../../components/Page';
import UnsubcribeAll from './unsubscribeAll';
import { useSnackbar } from 'notistack';
import { MIconButton } from '../../components/@material-extend';
import { Icon } from '@iconify/react';

const RootStyle = styled(Page)(({ theme }) => ({
  marginTop: '100px',
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: '500px',
  margin: '50px auto',
  textAlign: 'center',
  '.preference-container': {
    marginTop: '40px'
  },
  ul: {
    textAlign: 'left',
    listStyle: 'none'
  },
  '.update': {
    width: '385px',
    height: '57px',
    color: '#fff',
    backgroundColor: '#1565D8',
    borderRadius: '30px',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '30px',
    marginTop: '25px',
    boxShadow: 'none',
    '@media (max-width: 385px)': {
      width: '100%'
    }
  },
  '.unsubscribe': {
    width: '385px',
    height: '57px',
    color: '#1565D8',
    border: '1px solid #1565D8',
    backgroundColor: '#fff',
    borderRadius: '30px',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '30px',
    boxShadow: 'none',
    '&:hover': {
      color: '#fff'
    },
    '@media (max-width: 385px)': {
      width: '100%'
    }
  },
  hr: {
    border: '1px solid #9C9A9A',
    margin: '50px auto'
  }
}));

export default function EmailPreferences() {
  const dispatch = useDispatch();
  const { encId } = useParams();
  const { isAuthenticated } = useAuth();
  const [showAck, setShowAck] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [preferenceList, setPreferenceList] = useState<{ [key: string]: boolean }>({});
  const {
    data: { preferences, userPreference, email },
    isLoading: preferenceLoading
  } = useSelector((state: RootState) => state?.emailPreferences);
  const { preferenceResponse, isLoading } = useSelector((state: RootState) => state?.user);

  useEffect(() => {
    if (!isAuthenticated) {
      dispatch(setShowLoginModalWithoutClose());
    } else {
      if (encId && ENABLE_TOKENIZED_PATH) {
        dispatch(getPreferencesList(encId));
      } else {
        dispatch(getPreferencesList());
      }
    }

    return () => {
      dispatch(resetPreferenceSuccess());
    };
  }, [isAuthenticated]);

  useEffect(() => {
    if (userPreference) {
      setPreferenceList(userPreference);
    }
  }, [userPreference]);

  useEffect(() => {
    if (preferenceResponse?.success) {
      enqueueSnackbar('Preference saved', {
        variant: 'success',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
  }, [preferenceResponse]);

  const onPreferenceChange = (e: any) => {
    setPreferenceList({ ...preferenceList, [e.target.value]: e.target.checked });
  };

  const onSubmit = () => {
    let filteredPef: { [key: string]: boolean } = {};
    Object.keys(preferenceList).forEach((it) => {
      if (preferenceList[it] === true) {
        filteredPef[it] = true;
      }
    });
    dispatch(savePreferences(filteredPef, encId));
  };

  const onUnSubscribeAll = () => {
    setPreferenceList({});
    dispatch(savePreferences({}, encId));
    setShowAck(true);
  };

  return showAck ? (
    <UnsubcribeAll />
  ) : (
    <RootStyle title="Email Preferences | TradingTown Space ">
      <Container>
        <ContentStyle>
          <h1>Email Preferences</h1>
          {email ? <h4>For: {email}</h4> : null}
          <div className="preference-container">
            <ul>
              {preferences &&
                preferences?.map((item: { [key: string]: any }) => (
                  <li key={item?.identifier}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={onPreferenceChange}
                          value={item?.identifier}
                          checked={!!preferenceList[item?.identifier]}
                        />
                      }
                      label={item?.label}
                    />
                  </li>
                ))}
            </ul>
            <Button
              className="update"
              variant="contained"
              onClick={onSubmit}
              disableElevation={true}
              disabled={isLoading || !preferences}
            >
              UPDATE PREFERENCES
            </Button>
            <hr />
            <Button
              className="unsubscribe"
              variant="contained"
              onClick={onUnSubscribeAll}
              disabled={!preferences}
              disableElevation={true}
            >
              UNSUBSCRIBE FROM ALL
            </Button>
          </div>
        </ContentStyle>
        <Backdrop
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, background: 'rgba(22,28,36, 0.5)' }}
          open={isLoading || preferenceLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    </RootStyle>
  );
}
