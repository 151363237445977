import { ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
// components
import Logo from '../components/Logo';
import { MHidden } from '../components/@material-extend';
import { ButtonTracking, stockBtnTabEventTracking, event_types } from 'components/amplitute';
import ContactFloatingButton from './main/ContactFloatingButton';

// ----------------------------------------------------------------------

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7)
  }
}));

// ----------------------------------------------------------------------

type AuthLayoutProps = {
  children: ReactNode;
};

export default function AuthLayout({ children }: AuthLayoutProps) {
  return (
    <HeaderStyle>
      <RouterLink
        to="/"
        onClick={() => {
          // ButtonTracking(
          //   `Logo Click ${window.location.pathname}`,
          //   window.location.href,
          //   window.location.pathname
          // );
          stockBtnTabEventTracking(
            'Authentication',
            '',
            event_types.btnClick,
            `Logo`,
            'Authentication',
            window.location.href
          );
        }}
      >
        <Logo />
      </RouterLink>
      <ContactFloatingButton />
      <MHidden width="smDown">
        <Typography
          variant="body2"
          sx={{
            mt: { md: -2 }
          }}
        >
          {children}
        </Typography>
      </MHidden>
    </HeaderStyle>
  );
}
