import { Icon } from '@iconify/react';
import { useState, useEffect, useMemo } from 'react';
import searchFill from '@iconify/icons-eva/search-fill';
import { useNavigate } from 'react-router-dom';
// material
import { styled, alpha } from '@mui/material/styles';
import {
  Box,
  Input,
  Slide,
  Button,
  InputAdornment,
  ClickAwayListener,
  Grid,
  Typography,
  TextField,
  Stack,
  Divider
} from '@mui/material';
// components
import { MIconButton } from '../../components/@material-extend';

// routes
import { PATH_STOCK } from '../../routes/paths';

// import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

import throttle from 'lodash/throttle';
import { useDispatch, useSelector, RootState } from 'redux/store';
import { searchStocks } from 'redux/slices/stockSearch';
import { getOptionByTag } from 'redux/slices/stock';
import { ButtonTracking, stockBtnTabEventTracking, event_types } from 'components/amplitute';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const SearchbarStyle = styled('div')(({ theme }) => ({
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  backgroundColor: `${alpha(theme.palette.background.default, 0.72)}`,
  [theme.breakpoints.up('md')]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

export default function Searchbar() {
  const navigate = useNavigate();
  const [isOpen, setOpen] = useState(false);
  // const [value, setValue] = useState('');

  const [value, setValue] = useState<any>([]);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<any>([]);

  const dispatch = useDispatch();
  const { stockSearchList } = useSelector((state: RootState) => state.stockSearch);

  useEffect(() => {
    setOptions(stockSearchList);
  }, [stockSearchList]);

  const fetch = useMemo(
    () =>
      throttle((request) => {
        dispatch(searchStocks(request));
        dispatch(getOptionByTag(request));
      }, 200),
    []
  );

  useEffect(() => {
    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch(inputValue);
  }, [value, inputValue, fetch]);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearchStock = (value: any) => {
    // ButtonTracking(
    //   `${value} Search from Header ${window.location.pathname}`,
    //   window.location.href,
    //   window.location.pathname
    // );
    stockBtnTabEventTracking(
      'Dashboard',
      value,
      event_types.btnClick,
      `Search from Header`,
      'Searchbar',
      window.location.href
    );
    navigate(PATH_STOCK.detail + '/' + value);
  };

  return (
    <Autocomplete
      size="small"
      fullWidth
      getOptionLabel={(option: any) => (typeof option === 'string' ? option : option?.ticker || '')}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      disableClearable
      includeInputInList
      autoHighlight
      // forcePopupIcon={false}
      noOptionsText="Sorry, no matches have been found ..."
      // filterSelectedOptions
      value={value}
      sx={{ maxWidth: 460, ml: 2 }}
      onChange={(event, newValue: any) => {
        // setOptions(newValue ? [newValue, ...options] : options);
        //@ts-ignore
        // setValue(newValue);
        // console.log('newValue: ', newValue);
        handleSearchStock(newValue?.ticker);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          // variant="standard"
          // label="Search Stocks"
          fullWidth
          autoFocus
          // disableUnderline
          placeholder="Search Stocks, ETFs & more"
          // InputProps={{
          //   endAdornment: (
          //     <InputAdornment position="start">
          //       <Box
          //         component={Icon}
          //         icon={searchFill}
          //         sx={{ color: 'text.disabled', width: 20, height: 20 }}
          //       />
          //     </InputAdornment>
          //   )
          // }}
          sx={{ bgcolor: '#F8F8F8', borderRadius: '8px' }}
        />
      )}
      renderOption={(props, option: any) => {
        return (
          <li {...props}>
            <Box
              display="flex"
              justifyContent="space-between"
              sx={{ borderBottom: '1px solid #cccccc', width: '100%' }}
            >
              <Box sx={{ flexGrow: 1 }}>
                <Typography color="primary">{option?.ticker}</Typography>
                <Typography variant="caption">{option?.data?.name}</Typography>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography color="text.secondary">{option?.data?.primary_exchange}</Typography>
              </Box>
            </Box>
          </li>
        );
      }}
    />
  );
}
