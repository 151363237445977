import { track } from '@amplitude/analytics-browser';
export const event_types = {
  screenView: 'ScreenView',
  tabClick: 'TabClick',
  linkClick: 'LinkClick',
  btnClick: 'ButtonClick',
  menuClick: 'MenuClick',
  clickSocial: 'click_social'
};
export const ButtonTracking = (refBtn = '', path = '', screen = '') => {
  return track(refBtn, {
    ButtonName: refBtn,
    ScreenName: screen,
    Path: path
  });
};
export const TabTracking = (refTab = '', path = '', screen = '') => {
  return track(refTab, {
    TabName: refTab,
    ScreenName: screen,
    Path: path
  });
};

export const screenViewTracking = (screen_name = '', event_type = '', location = '', path = '') => {
  return track(event_type, {
    ScreenName: screen_name,
    event_type: event_type,
    location: location,
    path: path
  });
};

export const stockScreenViewTracking = (
  screen_name = '',
  stock_name = '',
  event_type = '',
  path = ''
) => {
  return track(event_type, {
    ScreenName: screen_name,
    stock_name: stock_name,
    event_type: event_type,
    path: path
  });
};

export const stockBtnTabEventTracking = (
  screen_name = '',
  stock_name = '',
  event_type = '',
  button_name = '',
  location = '',
  path = ''
) => {
  return track(event_type, {
    ScreenName: screen_name,
    stock_name: stock_name,
    event_type: event_type,
    button_name: button_name,
    location: location,
    path: path
  });
};

export const signUpTracking = (
  path = '',
  user_id = '',
  utm_campaign = '',
  utm_medium = '',
  utm_source = ''
) => {
  return track('Signed up', {
    ScreenName: 'RegisterPage',
    event_type: 'Signed up',
    path: path,
    user_id: user_id,
    utm_source: utm_source,
    utm_medium: utm_medium,
    utm_campaign: utm_campaign
  });
};

export const signInTracking = (path = '', user_id = '') => {
  return track('Signed in', {
    ScreenName: 'LoginPage',
    event_type: 'Signed in',
    path: path,
    user_id: user_id
  });
};

export const socialBtnTracking = (
  screen_name = '',
  stock_name = '',
  event_type = '',
  button_name = '',
  location = '',
  path = '',
  platform = ''
) => {
  return track(event_type, {
    ScreenName: screen_name,
    stock_name: stock_name,
    event_type: event_type,
    button_name: button_name,
    location: location,
    path: path,
    platform
  });
};

export const trackEvent = (event_type: string, props: { [key: string]: any }) => {
  return track(event_type, {
    ...props
  });
};
