import { Card, Box, Button, Typography } from '@mui/material';
import { PATH_AUTH } from 'routes/paths';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { init } from '@amplitude/analytics-browser';
import { API_KEY } from 'utils/apiKeyAmplitute';
import { RootState, useDispatch, useSelector } from '../../redux/store';
import {
  ButtonTracking,
  stockBtnTabEventTracking,
  event_types,
  trackEvent
} from 'components/amplitute';

export default function SignInTooltip({ redirectURL, actionType, stockwishlistSymbol }: any) {
  const { stockSymbol: stockSym = '' } = useParams();
  const stockSymbol = useMemo(() => stockSym, [stockSym]);
  const { stock, quote } = useSelector((state: RootState) => state.stock);
  useEffect(() => {
    init(API_KEY, 'N/A');
  }, []);

  const trackBtnClick = () => {
    if (window.location.href.includes('/insidertrading/profile')) {
      trackEvent('click_sign_in', {
        ScreenName: 'InsiderTrading Profile',
        path: window.location.href,
        source: 'watchlist'
      });
    } else {
      stockBtnTabEventTracking(
        'LoginToolTip',
        '',
        event_types.btnClick,
        `Sign In Tooltip`,
        'Authentication',
        window.location.href
      );
    }
  };
  return (
    <Card>
      <Box textAlign={'center'} sx={{ p: 5 }}>
        <Typography>
          You haven't sign in to <br /> <strong>Trading Town</strong>,please sign in first.
        </Typography>
        <Button
          variant="contained"
          component={RouterLink}
          to={{
            pathname: PATH_AUTH.login
          }}
          // state={{ redirectURL: window.location.pathname }}
          state={{
            redirectURL: redirectURL,
            actionType: actionType,
            stockSymbol: stockSymbol,
            stockwishlistSymbol: stockwishlistSymbol
          }}
          onClick={trackBtnClick}
        >
          Sign In
        </Button>
        <img
          src={'/static/icons/stockIcon.svg'}
          alt="stock icon"
          width={57}
          height={70}
          style={{ position: 'absolute', marginTop: '-25px' }}
        />
      </Box>
    </Card>
  );
}
