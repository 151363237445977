import { Icon } from '@iconify/react';
import homeFill from '@iconify/icons-eva/home-fill';
import fileFill from '@iconify/icons-eva/file-fill';
import roundGrain from '@iconify/icons-ic/round-grain';
import bookOpenFill from '@iconify/icons-eva/book-open-fill';
import { NavConfig } from '../../@types/nav-config';
// routes
import {
  PATH_AUTH,
  PATH_DOCS,
  PATH_PAGE,
  PATH_DASHBOARD,
  PATH_WATCHLIST
} from '../../routes/paths';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22
};

const menuConfig: NavConfig = {
  default: [
    {
      title: 'Blog',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: 'https://medium.com/@tradingtownofcmu',
      isAuthenticated: undefined
    },
    {
      title: 'Watchlist',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: PATH_WATCHLIST.root,
      isAuthenticated: undefined
    },
    {
      title: 'Profile',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: PATH_DASHBOARD.root,
      isAuthenticated: true
    }
  ],
  '/terms': [],
  '/policy': [],
  '/email-preference': [],
  '/insidertrading': [
    {
      title: 'Sign in',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: PATH_AUTH.login,
      isAuthenticated: undefined,
      redirectURL: '/insidertrading'
    },
    {
      title: 'Email Alerts',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/email-preference',
      isAuthenticated: true,
      menuItemOnClick: () => {}
    },
    {
      title: 'Sign out',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/insidertrading',
      isAuthenticated: true,
      redirectURL: '/insidertrading',
      menuItemOnClick: () => {}
    }
  ],
  '/insidertrading/activities': [
    {
      title: 'Sign in',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: PATH_AUTH.login,
      isAuthenticated: undefined,
      redirectURL: '/insidertrading/activities'
    },
    {
      title: 'Email Alerts',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/email-preference',
      isAuthenticated: true,
      menuItemOnClick: () => {}
    },
    {
      title: 'Sign out',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/insidertrading/activities',
      isAuthenticated: true,
      redirectURL: '/insidertrading/activities',
      menuItemOnClick: () => {}
    }
  ],
  '/insidertrading/traders': [
    {
      title: 'Sign in',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: PATH_AUTH.login,
      isAuthenticated: undefined,
      redirectURL: '/insidertrading/traders'
    },
    {
      title: 'Email Alerts',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/email-preference',
      isAuthenticated: true,
      menuItemOnClick: () => {}
    },
    {
      title: 'Sign out',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/insidertrading/traders',
      isAuthenticated: true,
      redirectURL: '/insidertrading/traders',
      menuItemOnClick: () => {}
    }
  ],
  '/insidertrading/profile/:insiderId': [
    {
      title: 'Sign in',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: PATH_AUTH.login,
      isAuthenticated: undefined,
      redirectURL: '/insidertrading/profile/:insiderId'
    },
    {
      title: 'Email Alerts',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/email-preference',
      isAuthenticated: true,
      menuItemOnClick: () => {}
    },
    {
      title: 'Sign out',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/insidertrading/profile/:insiderId',
      isAuthenticated: true,
      redirectURL: '/insidertrading/profile/:insiderId',
      menuItemOnClick: () => {}
    }
  ],
  '/insidertrading/promotion': [
    {
      title: 'Sign in',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: PATH_AUTH.login,
      isAuthenticated: undefined,
      redirectURL: '/insidertrading'
    },
    {
      title: 'Email Alerts',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/email-preference',
      isAuthenticated: true,
      menuItemOnClick: () => {}
    },
    {
      title: 'Sign out',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/insidertrading/promotion',
      isAuthenticated: true,
      redirectURL: '/insidertrading/promotion',
      menuItemOnClick: () => {}
    }
  ],
  '/insidertrading/algo_trading_showcase': [
    {
      title: 'Sign in',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: PATH_AUTH.login,
      isAuthenticated: undefined,
      redirectURL: '/insidertrading'
    },
    {
      title: 'Email Alerts',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/email-preference',
      isAuthenticated: true,
      menuItemOnClick: () => {}
    },
    {
      title: 'Sign out',
      icon: <Icon icon={homeFill} {...ICON_SIZE} />,
      path: '/insidertrading/promotion',
      isAuthenticated: true,
      redirectURL: '/insidertrading/promotion',
      menuItemOnClick: () => {}
    }
  ]
};

export default menuConfig;
