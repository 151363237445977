import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

type TickerState = {
  isLoading: boolean;
  error: boolean;
  stockSearchList: any;
};

const initialState: TickerState = {
  isLoading: false,
  error: false,
  stockSearchList: []
};

const slice = createSlice({
  name: 'stockSearch',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // RESET STATE
    resetState(state) {
      state.isLoading = false;
      state.error = false;
      state.stockSearchList = [];
    },

    getStockSearchListSuccess(state, action) {
      state.isLoading = false;
      state.stockSearchList = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions

// ----------------------------------------------------------------------

export function resetState() {
  return async () => {
    dispatch(slice.actions.resetState());
  };
}

export function searchStocks(searchText: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/api/v1/stocks/searchStocks', { searchText });
      dispatch(slice.actions.getStockSearchListSuccess(response.data.stocks));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
