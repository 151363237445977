import { styled } from '@mui/material/styles';
import { Container, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Page from '../../components/Page';

const RootStyle = styled(Page)(({ theme }) => ({
  marginTop: '100px',
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: '500px',
  margin: '50px auto',
  textAlign: 'center',
  '.back-to-site': {
    width: '385px',
    height: '57px',
    color: '#fff',
    backgroundColor: '#1565D8',
    borderRadius: '30px',
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '30px',
    marginTop: '25px',
    '@media (max-width: 385px)': {
      width: '100%'
    }
  }
}));

export default function UnsubcribeAll() {
  const navigate = useNavigate();

  const onBackToHome = () => {
    navigate('/');
  };

  return (
    <RootStyle title="Unsubscribe All Preferences | TradingTown Space ">
      <Container>
        <ContentStyle>
          <h1>You’ve unsubscribed from all Email Alerts</h1>
          <Button variant="contained" className="back-to-site" onClick={onBackToHome}>
            Back to Main Site
          </Button>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
