import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useEffect, useState, ReactNode } from 'react';
import moment from 'moment';
import { RootState, useDispatch, useSelector } from '../../redux/store';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Container,
  Typography,
  TextField,
  Chip,
  Button,
  ButtonProps,
  Card,
  CardHeader,
  Grid
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
// routes
import { PATH_INSIDER_TRADING } from '../../routes/paths';
// components
import Page from '../../components/Page';
import { setTheme } from '../../redux/slices/theme';
import { stockBtnTabEventTracking, event_types, trackEvent } from '../../components/amplitute';
import useAuth from 'hooks/useAuth';
import { setShowLoginModal, setShowRegistrationModal } from '../../redux/slices/user';

// start here
import PortfolioChart from './AlgoTradingChart';
import ActivitiesList from './AlgoTradingActivitiesList';
import { getAlgoTradingChartData, getAlgoTradingActivitiesData } from '../../redux/slices/insiders';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const customizeStrategyIndustries = [
  'Energy',
  'Materials',
  'Industrials',
  'Consumer Discretionary',
  'Consumer Staples',
  'Health Care',
  'Financials',
  'Information Technology',
  'Communication Services',
  'Utilities',
  'Real Estate'
];

const customizeStrategyInsiderTitles = [
  'CEO',
  'CFO',
  'CXO',
  'SVP',
  'Any Officer',
  'Director',
  '10% Owner'
];

// ----------------------------------------------------------------------
interface TrackNowButtonProps extends ButtonProps {
  component?: ReactNode;
  to?: string;
}

const RootStyle = styled(Page)(({ theme }) => ({
  // display: 'flex',
  // flexDirection: 'column', // Ensure that the direction is column
  // justifyContent: 'flex-start', // Start aligning children from the top
  // alignItems: 'stretch', // Stretch children to fill the width
  // width: '100%', // Take up full available width
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const ContentStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  '.textCnt': {
    width: '80%'
  },
  '.imgCnt': {
    width: '60%',
    paddingLeft: '5px',
    paddingRight: '5px',
    paddingTop: '5px'
  },
  '.cardCntLeft': {
    width: '60%',
    paddingLeft: '5px',
    paddingRight: '5px',
    paddingTop: '5px',
    marginRight: '5px',
    marginBottom: '5px'
  },
  '.cardCntRight': {
    width: '60%',
    paddingLeft: '5px',
    paddingRight: '5px',
    paddingTop: '5px',
    marginLeft: '5px',
    marginBottom: '5px'
  },
  marginTop: '100px',
  marginBottom: '0px',
  [theme.breakpoints.down('md')]: {
    // For mobile
    flexDirection: 'column',
    marginTop: '60px',
    marginBottom: 0,
    paddingTop: '40px',
    // paddingBottom: '90px',
    textAlign: 'center',
    '.textCnt': {
      width: '100%'
    },
    '.imgCnt': {
      width: '100%'
    },
    '.cardCntLeft': {
      width: '100%',
      paddingLeft: '5px',
      paddingRight: '5px',
      paddingTop: '5px',
      marginBottom: '10px'
    },
    '.cardCntRight': {
      width: '100%',
      paddingLeft: '5px',
      paddingRight: '5px',
      paddingTop: '5px',
      marginBottom: '5px',
      marginLeft: '0px'
    },
    '.imgCnt img': {
      marginLeft: '0px'
    }
  }
}));

const PageHeading = styled(Typography)(({ theme }) => ({
  color: '#072146',
  fontSize: '75.853px',
  fontStyle: 'normal',
  lineHeight: '109%',
  letterSpacing: '-1.034px',
  fontWeight: 800,
  paddingTop: '30px',
  [theme.breakpoints.down('md')]: {
    fontSize: '42px',
    paddingTop: '0'
  }
}));

const Message = styled('p')(({ theme }) => ({
  color: '#212B36',
  fontSize: '18px',
  fontWeight: 300,
  lineHeight: '149.5%',
  padding: '10px 0 10px 0',
  [theme.breakpoints.down('md')]: {
    fontSize: '16px'
  }
}));

const TradingContainer = styled(Container)(({ theme }) => ({
  background: 'radial-gradient(circle farthest-side at 55% 53%, #A2C4F6 0%, transparent 34%);',
  [theme.breakpoints.down('md')]: {
    background:
      'radial-gradient(circle farthest-side at 15% 74%, #A2C4F6 0%, #F6F9FE 35%, transparent 100%)'
  }
}));

const BgContainer = styled(Box)(({ theme }) => ({
  background: 'radial-gradient(circle at 79% 42%, #B6D9FB 0%, transparent 19%)',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    background: 'radial-gradient(circle farthest-side at 90% 60%, #A2C4F6 0%, #F6F9FE 45%)'
  }
}));

const TopBanner = styled('div')(({ theme }) => ({
  backgroundColor: '#4887E0', // Adjust the color to match the blue you want
  color: 'white',
  padding: theme.spacing(2), // Adjust the spacing as needed
  textAlign: 'center',
  paddingTop: '80px',
  paddingRight: '200px',
  paddingLeft: '200px',
  // add more styles as required for your banner
  [theme.breakpoints.down('md')]: {
    paddingRight: '10px',
    paddingLeft: '10px',
    paddingTop: '40px'
  }
}));

const TrackNowButton = styled(Button)<TrackNowButtonProps>(({ theme }) => ({
  // marginTop: '10px',
  // marginBottom: '10px',
  [theme.breakpoints.up('md')]: {
    width: '399px',
    height: '50.501px'
  },
  [theme.breakpoints.down('md')]: {
    width: '296px',
    height: '50.501px'
  },
  // padding: '11.14px 14.853px',
  borderRadius: '20px',
  background: '#1565D8',
  color: 'rgba(255, 255, 255, 1)',
  letterSpacing: '3.06px',
  boxShadow: 'none'
}));

// ----------------------------------------------------------------------

export default function Promotion() {
  const dispatch = useDispatch();
  const Location = useLocation();
  const { isAuthenticated } = useAuth();

  const algoTradingChartData = useSelector(
    (state: RootState) => state.insiders.algoTradingChartData
  );

  const insiderTradingActivities = useSelector(
    (state: RootState) => state?.insiders?.algoTradingActivities
  );

  const [selectedIndustry, setSelectedIndustry] = useState<string[]>([
    'Information Technology',
    'Health Care'
  ]);
  const [selectedInsiderTitle, setSelectInsiderTitle] = useState<string[]>(['CEO', 'CFO']);
  const [entryConditions, setEntryConditions] = useState(
    'When corporate insiders buy more than $200,000 in stocks'
  );
  const [exitConditions, setExitConditions] = useState(
    'When insiders sell more than 10% of their holdings'
  );

  const handleChangeIndustry = (event: SelectChangeEvent<typeof selectedIndustry>) => {
    const {
      target: { value }
    } = event;
    setSelectedIndustry(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleChangeInsiderTitle = (event: SelectChangeEvent<typeof selectedInsiderTitle>) => {
    const {
      target: { value }
    } = event;
    setSelectInsiderTitle(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const entryConditionFieldChange = (e: any) => {
    setEntryConditions(e.target.value);
  };

  const exitConditionFieldChange = (e: any) => {
    setExitConditions(e.target.value);
  };

  useEffect(() => {
    dispatch(getAlgoTradingChartData());
  }, []);

  useEffect(() => {
    dispatch(getAlgoTradingActivitiesData());
  }, []);

  console.log('insiderTradingActivities:', insiderTradingActivities);

  useEffect(() => {
    dispatch(setTheme('bluebg'));

    return () => {
      dispatch(setTheme(''));
    };
  }, []);

  const trackSubscribeEvent = () => {
    stockBtnTabEventTracking(
      'InsiderTrading',
      '',
      'click_sign_up_waitlist_algo_trading_showcase',
      'Sign Up For Waitlist',
      'Algo Trading Showcase',
      window.location.href
    );
  };

  const subscribeBtnClick = () => {
    dispatch(setShowRegistrationModal());
    trackSubscribeEvent();
  };

  return (
    <RootStyle title="Insider Trading | TradingTown Space ">
      <BgContainer>
        {/* <TopBanner>
          <ContentStyle style={{ marginTop: '0px', marginBottom: '10px', padding: '5px' }}>
            <div className="cardCntLeft" style={{ paddingTop: '20px' }}>
              <CardHeader
                title={
                  <Box>
                    <Box display={'flex'} alignItems="center" style={{ textAlign: 'center' }}>
                      <Typography component={'span'} variant="h2">
                        TradingTown AI Bot
                      </Typography>
                    </Box>
                  </Box>
                }
              />
              <p
                style={{
                  fontSize: '32px',
                  textAlign: 'left',
                  paddingLeft: '25px',
                  paddingBottom: '40px'
                }}
              >
                Maximize Your Returns with AI-Powered strategy based on Insider Trading Activities
              </p>
              {!isAuthenticated && (
                <TrackNowButton
                  style={{ marginBottom: '10px', background: '#FFFFFF', color: '#1565D8' }}
                  variant="contained"
                  onClick={subscribeBtnClick}
                >
                  JOIN WAITLIST NOW
                </TrackNowButton>
              )}
            </div>
            <div className="cardCntRight" style={{ paddingTop: '20px' }}>
              <img
                src="/static/insidertrading/algo_trading_showcase_bot_icon.png"
                alt="insider trading ai bot"
                height="300px"
                style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
              />
            </div>
          </ContentStyle>
        </TopBanner> */}
        <TradingContainer>
          <ContentStyle style={{ marginTop: '0px', padding: '5px', paddingTop: '100px' }}>
            {algoTradingChartData && <PortfolioChart ratio={algoTradingChartData} />}
          </ContentStyle>
          <ContentStyle style={{ padding: '5px', marginTop: '5px' }}>
            <Card className="cardCntLeft" sx={{ textAlign: 'left' }}>
              <CardHeader
                title={
                  <Box>
                    <Box display={'flex'} alignItems="center">
                      <Typography component={'span'} variant="h4">
                        About the Strategy
                      </Typography>
                    </Box>
                  </Box>
                }
              />
              <p style={{ padding: '25px', fontSize: '14px' }}>
                The strategy follows purchases of publicly disclosed trades by corporate officers or
                directors, taking their historical stock purchase performance as reference. The
                insider must have at least four transactions in the past to be considered in our
                strategy. A position is entered at the end of the week following an insider purchase
                using 10% of the current uninvested cash, and is held for a period of between 14
                days and 90 days, optimizing for the highest return rate. The strategy invests in US
                common stock assets with market cap at least US$100M.
              </p>
              <p style={{ padding: '25px', paddingTop: '0px', fontSize: '14px' }}>
                The Securities Exchange Act of 1934 regulates secondary financial markets to ensure
                a transparent and fair environment for investors. It required corporate insiders to
                publicly file and disclose financial transactions involving stock and other
                securities within two business days of making a transaction.
              </p>
              <p
                style={{ padding: '25px', paddingTop: '0px', textAlign: 'right', fontSize: '14px' }}
              >
                <a href="https://www.investopedia.com/terms/s/seact1934.asp">
                  Learn more about the Securities Exchange Act of 1934 {`>`}
                </a>
              </p>
            </Card>
            <Card className="cardCntRight">
              <CardHeader
                title={
                  <Box>
                    <Box display={'flex'} alignItems="center">
                      <Typography component={'span'} variant="h4">
                        Performance Characteristics
                      </Typography>
                    </Box>
                  </Box>
                }
              />
              <Box
                padding={2}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                height="70%"
              >
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography variant="subtitle1">Win Rate</Typography>
                    <Typography variant="h6">71.4%</Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="subtitle1">CAGR</Typography>
                    <Typography variant="h6" style={{ color: 'green' }}>
                      +44.9%
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography variant="subtitle1">Total Trades</Typography>
                    <Typography variant="h6">242</Typography>
                  </Grid>
                </Grid>
                <p style={{ padding: '25px' }}></p>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1">Average Sharpe Ratio</Typography>
                    <Typography variant="h6">1.8</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1">Max Drawdown</Typography>
                    <Typography variant="h6">26.9%</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Card>
          </ContentStyle>
          <ContentStyle style={{ marginTop: '0px', marginBottom: '10px', padding: '5px' }}>
            <Card sx={{ width: '100%' }}>
              <CardHeader
                title={
                  <Box>
                    <Box display={'flex'} alignItems="center">
                      <Typography component={'span'} variant="h4">
                        Trade Logs
                      </Typography>
                    </Box>
                  </Box>
                }
              />
              {insiderTradingActivities && (
                <ActivitiesList
                  insiderTradingActivities={insiderTradingActivities}
                  onPageChange={null}
                  onSort={null}
                ></ActivitiesList>
              )}
              {!isAuthenticated && (
                <TrackNowButton
                  style={{ marginTop: '30px', marginBottom: '40px' }}
                  variant="contained"
                  onClick={subscribeBtnClick}
                >
                  SIGN UP FOR WAITLIST
                </TrackNowButton>
              )}
            </Card>
          </ContentStyle>
          <ContentStyle style={{ marginTop: '0px', marginBottom: '10px', padding: '5px' }}>
            <div className="cardCntLeft" style={{ paddingTop: '20px' }}>
              <h3>Customize Your Strategy and Copy It</h3>
              {/* <CardHeader
                title={
                  <Box>
                    <Box display={'flex'} alignItems="center" style={{ textAlign: 'center' }}>
                      <Typography component={'span'} variant="h5">
                        Easily Customize Your Strategy
                      </Typography>
                    </Box>
                  </Box>
                }
              /> */}
              <p style={{ padding: '25px', textAlign: 'left' }}>
                Backtest your strategy with our corporate insider trading data. You can also easily
                personalize your strategy by picking industries, insider titles, the minimum number
                of historical transactions, entry conditions, and exit conditions.
              </p>
              <p style={{ padding: '25px', textAlign: 'left' }}>
                All these can be easily done with our custom corporate insider strategy builder.
              </p>
              {!isAuthenticated && (
                <TrackNowButton
                  style={{ marginBottom: '10px' }}
                  variant="contained"
                  onClick={subscribeBtnClick}
                >
                  SIGN UP FOR WAITLIST
                </TrackNowButton>
              )}
            </div>
            <Card className="cardCntRight" sx={{ textAlign: 'left' }}>
              <CardHeader
                title={
                  <Box>
                    <Box display={'flex'} alignItems="center">
                      <Typography component={'span'} variant="h4">
                        Customize Your Strategy
                      </Typography>
                    </Box>
                  </Box>
                }
                style={{ paddingBottom: '25px' }}
              />
              <div style={{ paddingLeft: '25px', paddingRight: '25px', paddingBottom: '10px' }}>
                <label>Industries:</label>
                <p></p>
                <Select
                  size="small"
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  value={selectedIndustry}
                  onChange={handleChangeIndustry}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                  sx={{ width: '100%' }}
                >
                  {customizeStrategyIndustries.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div style={{ paddingLeft: '25px', paddingRight: '25px', paddingBottom: '10px' }}>
                <label>Insider Titles:</label>
                <p></p>
                <Select
                  size="small"
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  multiple
                  value={selectedInsiderTitle}
                  onChange={handleChangeInsiderTitle}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                  sx={{ width: '100%' }}
                >
                  {customizeStrategyInsiderTitles.map((name) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div style={{ paddingLeft: '25px', paddingRight: '25px', paddingBottom: '10px' }}>
                <label>Entry Conditions:</label>
                <p></p>
                <TextField
                  onChange={entryConditionFieldChange}
                  name="company"
                  size="small"
                  // label={filters.company ? '' : 'Company name'}
                  value={entryConditions}
                  InputLabelProps={{
                    shrink: false
                  }}
                  sx={{ width: '100%' }}
                />
              </div>
              <div style={{ paddingLeft: '25px', paddingRight: '25px', paddingBottom: '40px' }}>
                <label>Exit Conditions:</label>
                <p></p>
                <TextField
                  onChange={exitConditionFieldChange}
                  name="company"
                  size="small"
                  // label={filters.company ? '' : 'Company name'}
                  value={exitConditions}
                  InputLabelProps={{
                    shrink: false
                  }}
                  sx={{ width: '100%' }}
                />
              </div>
            </Card>
          </ContentStyle>
        </TradingContainer>
      </BgContainer>
    </RootStyle>
  );
}
