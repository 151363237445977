// material
import { styled } from '@mui/material/styles';
import { Box, Typography, Container } from '@mui/material';
// components
import { MotionContainer } from '../components/animate';
import Page from '../components/Page';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10)
}));

// ----------------------------------------------------------------------
const Terms = () => {
  return (
    <RootStyle title="Terms of Use and Service">
      <Container>
        <MotionContainer initial="initial" open>
          <Box sx={{ margin: 'auto', textAlign: 'justify' }}>
            <Typography variant="h3" paragraph>
              Terms of Use and Service
            </Typography>
            <Typography variant="h5" paragraph>
              Introduction
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              These Terms of Use and Service (the “Terms” or “Agreement”) set forth the terms and
              conditions under which individuals residing in the United States may use the Trading
              Site and/or the Trading Town Services (as defined below). Certain Trading Town
              Services are subject to additional policies, rules and terms and conditions, which you
              may be required to agree to as a condition of using those Services (“Additional
              Terms”). In those cases, the applicable Additional Terms are set forth in the printed
              or online Service materials relating to those Services.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary', paddingLeft: '5%' }}>
              Please read these Terms and any applicable Additional Terms before using the Site or
              the Services. By using the Site or the Services, you hereby represent, warrant,
              understand, agree to and accept these Terms and any applicable Additional Terms in
              their entirety whether or not you register as a user of the Site or Services
              (“Registered Users”). If you represent a legal entity, you certify that you are
              entitled by such a legal entity to conclude the Terms as the legal entity you
              represent.
              <br />
              Trading Town’s Privacy Policy is available on a separate page. The Privacy
              Policyexplains how Trading Town processes information about you. You shall understand
              that through your use of the website you acknowledge the processing of this
              information shall be undertaken in accordance with the Privacy Policy. If you object
              to anything in these Terms, the Privacy Policy or any applicable Additional Terms, do
              not use the Site or the Services.
              <br />
              These Terms are subject to change by Trading Town at any time, subject to the
              following notification process. We will notify you about material changes in these
              Terms by sending a notice to the email address registered in your account, or by
              placing a prominent notice on our Site, so that you can choose whether to continue
              using our Services. Material changes will go into effect no less than 30 days after we
              notify you. Non-material changes or clarifications will take effect immediately upon
              posting of the updated Terms on our Site. You should periodically check Terms on our
              Site for updates. Any use of the Site or the Services by you after the effective date
              of any changes will constitute your acceptance of such changes. These Terms supersede
              all prior versions of the Terms.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              For purposes of these Terms, and except to the extent expressly excluded below, the
              Site shall mean <a href="https://tradingtown.space">www.tradingtown.space</a> and any
              other websites, web pages, mobile applications and mobile websites operated by Trading
              Town in the United States, and the Services shall mean any of the various services
              that Trading Town provides through the Site or any other channels, including without
              limitation, over the telephone. However, the terms Site and Services do not include or
              involve the following:
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary', paddingLeft: '5%' }}>
              Websites, web pages, mobile applications, and mobile websites (and services offered
              through such channels) operated by Trading Town and its subsidiaries which have
              separate terms of service that do not expressly incorporate these Terms by reference.
              <br />
              Description of Services, Limitations, User Responsibilities
            </Typography>
            <br />
            <Typography variant="h5" paragraph>
              2. Description of Services; Limitations; User Responsibilities
            </Typography>
            <Typography variant="h5" paragraph>
              2.1 About Our Services
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              Trading Town offers various Services to help its users find information on stocks,
              including price, related news, past data aggregate, insider trading news, and option
              activities. The Services we offer include, among others:
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary', paddingLeft: '5%' }}>
              We provide a platform for individuals seeking information related to stocks and stock
              trading strategies. For individual stocks, we provide aggregated information about,
              but not limited to, stock price, analyst forecast, related news, financial reports,
              insider trading activity, and unusual option activity.
            </Typography>
            <br />
            <Typography variant="h5" paragraph>
              2.2 Limitations of our Services
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              We offer Services to help our users find and research stocks. However, except where
              specifically provided in these Terms, in any applicable Additional Terms, or on the
              Site itself
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary', paddingLeft: '5%' }}>
              We do not provide financial, legal, tax, or investment advice or recommendations
              unless specifically identified as an individualized recommendation in writing.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              Please review carefully the Release of Liability Form Third-Party Content and Conduct
              that appears below for important limitations on Trading Town’s liability to which you
              are agreeing by using the Site and Services.
            </Typography>
            <br />
            <Typography variant="h5" paragraph>
              2.3 User Responsibilities
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              Registered Users are solely responsible for researching and verifying information
              about stocks.
              <br />
              The Site allows you to use Services available on the Site. Users shall not use the
              Services for illegal purposes.
            </Typography>
            <br />
            <Typography variant="h5" paragraph>
              3. Release of Liability for Conduct and Disputes
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              By using this Site or our Services, you hereby represent, understand, and expressly
              agree to release and hold Trading Town (and Trading Town’s officers, directors,
              shareholders, employees, parents, subsidiaries, other affiliates, successors,
              assignees, agents, representatives, advertisers, marketing partners, licensors,
              independent contractors, recruiters, corporate partners or resellers (“Affiliates”))
              harmless from any claim or controversy that may arise out of:
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary', paddingLeft: '5%' }}>
              the actions or inactions of, or the relationship between, you and any other user(s) of
              the Site; and
              <br />
              any information, instruction, advice or services created by any other user, or other
              third party that appeared on, or was communicated through, the Site.
            </Typography>
            <br />
            <Typography sx={{ color: 'text.secondary' }}>
              Trading Town and its Affiliates expressly disclaims any liability whatsoever for any
              damage, suits, claims, and/or controversies that have arisen or may arise, whether
              known or unknown, from the above.
            </Typography>
            <br />
            <Typography variant="h5" paragraph>
              4. Your Account
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              When using the website, you shall be responsible for ensuring the confidentiality of
              your account, password and other credentials and for secure access to your device. You
              shall not assign your account to anyone. Trading Town is not responsible for
              unauthorized access to your account that results from misappropriation or theft of
              your account. Trading Town may refuse or cancel service, terminate your account, and
              remove or edit content.
              <br />
              Trading Town is intended for people 18 or over. Trading Town will not knowingly
              collect any information from individuals under 13. Should we determine that you do not
              meet the age requirements for using our Site or Services, your registration will be
              terminated immediately.
            </Typography>
            <br />
            <Typography variant="h5" paragraph>
              5. Payment And Refund Policy
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              In order to utilize some Trading Town Services or product offerings, the user of such
              Services or product offerings must pay Trading Town either a recurring
              subscription,one-time, or other fees. In addition, the user is responsible for any
              state or local sales taxes associated with the Services or product offerings
              purchased.
              <br />
              Trading Town may, at our sole discretion, set fees for using the Site for you. All
              prices are published separately on relevant pages on the Site. We may, at our sole
              discretion, at any time change any fees.
            </Typography>
            <br />
            <Typography variant="h5" paragraph>
              5.1 Billing and Payment
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              If you sign up for a Trading Town paid membership subscription, or for a service or
              product that includes a recurring fee, you agree to pay Trading Town all charges
              associated with the subscription, service, or product you choose, as described on the
              Site at the time you submit your payment information. You also authorize Trading Town,
              or a third-party payment processor that works on our behalf, to charge your chosen
              payment method according to the terms of your chosen subscription, Service, or
              product. Trading Town reserves the right to correct any errors or mistakes that it
              makes even if it has already requested or received payment.
              <br />
              To the extent you elect to purchase other Services or product offerings we may offer
              for a non-recurring fee, you authorize Trading Town to charge your chosen payment
              provider for the Services and/or products you purchase. You agree that if Trading Town
              already has your credit card on file as a result of prior purchases you have made, we
              may charge that credit card for the additional Services or products you purchase.
              <br />
              We may use certified payment systems, which also may have their commissions. Such
              commissions may be implied on you when you choose a particular payment system.
              Detailed information about commissions of such payment systems may be found on their
              websites.
            </Typography>
            <br />
            <Typography variant="h5" paragraph>
              6. Third Party Services
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              The Site may include links to other websites, applications, and platforms (hereinafter
              the “Linked Sites”).
              <br />
              Trading Town does not control the Linked Sites, and shall not be responsible for the
              content and other materials of the Linked Sites. Trading Town makes these links
              available to you for providing the functionality or services on the Site.
            </Typography>
            <br />
            <Typography variant="h5" paragraph>
              7. Prohibited Uses and Intellectual Property
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              Trading Town grants you a non-transferable, non-exclusive, revocable license to access
              and use the Site from one device in accordance with the Terms. You shall not use the
              Site and Services for unlawful or prohibited purpose. You may not use theSite in a way
              that may disable, damage, or interfere in the Site.
              <br />
              All content present on the Site includes text, code, graphics, logos, images,
              compilation,software used on the Site (hereinafter and hereinbefore the “Content”).
              The Content is the property of the Company or its Affiliates and protected by
              intellectual property laws that protect such rights. You agree to use all copyright
              and other proprietary notices or restrictions contained in the Content and you are
              prohibited from changing the Content.
              <br />
              You may not publish, transmit, modify, reverse engineer, participate in the transfer,
              or create and sell derivative works, or in any way use any of the Content. Your
              enjoyment of the Site and Services shall not entitle you to make any illegal and
              disallowed use of the Content, and in particular you shall not change proprietary
              rights or notices in the Content. You shall use the Content only for your personal and
              non-commercial use. Trading Town does not grant you any licenses to the intellectual
              property of Trading Town.
            </Typography>
            <br />
            <Typography variant="h5" paragraph>
              8. Right to Use Materials
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              By posting, uploading, inputting, providing or submitting your Content you are
              granting Trading Town to use your Content in connection with the operation of
              Company’s business including, but not limited to, the rights to transmit, publicly
              display, distribute, publicly perform, copy, reproduce, and translate your Content;
              and to publish your name in connection with your Content.
              <br />
              No compensation shall be paid with regard to the use of your Content. Trading Town
              shall have no obligation to publish or enjoy any Content you may send us and may
              remove your Content at any time. By posting, uploading, inputting, providing or
              submitting your Content you warrant and represent that you own all of the rights to
              your Content.
            </Typography>
            <br />
            <Typography variant="h5" paragraph>
              9. Disclaimer of Certain Liabilities
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              The information and materials contained on the Site, including text, graphics,
              information, links or other items are provided “as is,” “as available.” Further,
              opinions, advice, statements, offers, or other information or content made available
              through the Services, but not directly by Trading Town, are those of their respective
              authors, and should not necessarily be relied upon. Such authors are solely
              responsible for such content.
              <br />
              <br />
              <b>
                Trading Town DOES NOT: (1) WARRANT THE ACCURACY, ADEQUACY OR COMPLETENESS OF THIS
                INFORMATION AND MATERIALS; (2) ADOPT, ENDORSE OR ACCEPT RESPONSIBILITY FOR THE
                ACCURACY OR RELIABILITY OF ANY OPINION, ADVICE, OR STATEMENT MADE BY ANY PARTY OTHER
                THAN TRADING TOWN; (3) WARRANT THAT YOUR USE OF THE SITE OR SERVICES WILL BE SECURE,
                FREE FROM COMPUTER VIRUSES, UNINTERRUPTED, ALWAYS AVAILABLE, ERROR-FREE OR WILL MEET
                YOUR REQUIREMENTS, OR THAT ANY DEFECTS IN THE SERVICES WILL BE CORRECTED; OR (4)
                GIVE ANY WARRANTIES OF FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT OF THIRD
                PARTY RIGHTS. TO THE EXTENT PERMITTED BY APPLICABLE LAW, TRADING TOWN EXPRESSLY
                EXCLUDES ALL CONDITIONS, WARRANTIES AND OTHER TERMS WHICH MIGHT OTHERWISE BE IMPLIED
                BY STATUTE, COMMON LAW OR THE LAW OF EQUITY AND DISCLAIMS LIABILITY FORERRORS OR
                OMISSIONS IN THIS INFORMATION AND MATERIALS.
                <br />
                IN ADDITION, AND WITHOUT LIMITING THE FOREGOING, TRADING TOWN MAKES NO
                REPRESENTATION OR WARRANTIES OF ANY KIND WHETHER EXPRESS OR IMPLIED REGARDING THE
                SUITABILITY OF ANY USER OF OUR SITE TO PROVIDE SERVICES AS A FINANCIAL ADVISER.
                <br />
                <br />
              </b>
              To the maximum extent permitted by the applicable law, in no event shall Trading Town
              be liable for any direct, indirect, incidental, consequential, special, punitive
              damages including, but not limited to, damages for loss of enjoyment, data or profits,
              in the connection with the enjoyment or execution of the Site in the context of the
              inability or delay to enjoy the Site or Services, or for any Content, or otherwise
              arising out of the enjoyment of the Site or Services , based on contract and
              non-contract liability or other reason.
              <br />
              If the exclusion or limitation of liability for damages, whether consequential or
              incidental, are prohibited in a particular case, the exclusion or limitation of
              liability shall not apply to you.
            </Typography>
            <br />
            <Typography variant="h5" paragraph>
              10. Indemnification
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              By agreeing to these Terms, users of the Site and Services agree to indemnify, defend
              and hold harmless Trading Town and its Affiliates from and against any and all claims,
              losses, expenses or demands of liability, including reasonable attorneys’ fees and
              costs incurred by Trading Town and its Affiliates in connection with any claim by a
              third party (including an intellectual property claim) arising out of (i) materials
              and content you submit, post or transmit through the Site, (ii) use of the Site or
              Services by you in violation of these Terms of Use or in violation of any applicable
              law, or (iii) any relationship or agreement formed with a user using the Site or
              Services. Users further agree that they will cooperate as reasonably required in the
              defense of such claims. Trading Town and its Affiliates reserve the right, at their
              own expense, to assume the exclusive defense and control of any matter otherwise
              subject to indemnification by users, and users shall not, in any event, settle any
              claim or matter without the written consent of Trading Town. Users further agree to
              hold harmless Trading Town and its Affiliates from any claim arising from a third
              party’s use of information or materials of any kind that users post to the Site.
            </Typography>
            <br />
            <Typography variant="h5" paragraph>
              11. Termination and Access Restriction
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              Trading Town may terminate your access and account to the Site and its related
              services or any part at any time, without notice, in case of your violation of the
              Terms.
            </Typography>
            <br />
            <Typography variant="h5" paragraph>
              12. Miscellaneous
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              The governing law of the Terms shall be the substantive laws of the country where
              Trading Town is set up, except the conflict of laws rules. You shall not use the Site
              in jurisdictions that do not give effect to all provisions of the Terms.
              <br />
              No joint venture, partnership, employment, or agency relationship shall be implied
              between you and Trading Town as a result of the Terms or use of the Site or Services.
              <br />
              Nothing in the Terms shall be a derogation of Trading Town’s Right to comply with
              governmental, court, police, and law enforcement requests or requirements regarding
              your enjoyment of the website.
              <br />
              If any part of the Terms is determined to be void or unenforceable in accordance with
              applicable law then the void or unenforceable clauses will be deemed superseded by
              valid and enforceable clauses shall be similar to the original version of the Terms
              and other parts and sections of the Terms shall be applicable to you and Trading Town.
              <br />
              The Terms constitute the entire agreement between you and Trading Town regarding the
              enjoyment of the Site and the Terms supersede all prior or communications and offers,
              whether electronic, oral or written, between you and Trading Town.
              <br />
              Trading Town and its Affiliates shall not be liable for a failure or delay to fulfill
              its obligations where the failure or delay results from any cause beyond Trading
              Town’s reasonable control, including technical failures, natural disasters, blockages,
              embargoes, riots, acts, regulation, legislation, or orders of government, terroristic
              acts, war, or any other force outside of Trading Town’s control.
              <br />
              In case of controversies, demands, claims, disputes, or causes of action between
              Trading Town and you relating to the Site or other related issues, or the Terms, you
              and Trading Town agree to attempt to resolve such controversies, demands, claims,
              disputes, or causes of action by good faith negotiation, and in case of failure of
              such negotiation, exclusively through the courts of the country where Trading Town is
              set up.
            </Typography>
            <br />
            <Typography variant="h5" paragraph>
              13. Complaints
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              We are committed to resolve any complaints about our collection or use of your
              personal data. If you would like to make a complaint regarding this Terms or our
              practices in relation to your personal data, please contact us through our Site. We
              will reply to your complaint as soon as we can and in any event, within 30 days. We
              hope to resolve any complaint brought to our attention, however if you feel that your
              complaint has not been adequately resolved, you reserve the right to contact your
              local data protection supervisory authority.
            </Typography>
            <br />
            <Typography variant="h5" paragraph>
              14. Contact Information
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              We welcome your comments or questions about our Terms. You may contact us through the
              contact information available on our Site.
            </Typography>
          </Box>
        </MotionContainer>
      </Container>
    </RootStyle>
  );
};

export default Terms;
