import { SearchBarConfig } from '../@types/search-bar';

const searchBarConfig: SearchBarConfig = {
  '/': false,
  '/insidertrading': false,
  '/stock': true,
  '/watchlist': true,
  '/404': true,
  '/terms': false,
  '/policy': false
};

export default searchBarConfig;
